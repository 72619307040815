// source: protobuf/model/merged.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var protobuf_validate_validate_pb = require('../../protobuf/validate/validate_pb.js');
goog.object.extend(proto, protobuf_validate_validate_pb);
goog.exportSymbol('proto.model.AddressProvider', null, global);
goog.exportSymbol('proto.model.AddressResponse', null, global);
goog.exportSymbol('proto.model.BorderEntranceResponse', null, global);
goog.exportSymbol('proto.model.BrokerageServicesCompanyDetails', null, global);
goog.exportSymbol('proto.model.CargoTypeDocTypeResponse', null, global);
goog.exportSymbol('proto.model.CargoTypeResponse', null, global);
goog.exportSymbol('proto.model.CategoryResponse', null, global);
goog.exportSymbol('proto.model.CheckedDocTypeResponse', null, global);
goog.exportSymbol('proto.model.CompanyBankAccountResponse', null, global);
goog.exportSymbol('proto.model.CompanyBankResponse', null, global);
goog.exportSymbol('proto.model.CompanyResponse', null, global);
goog.exportSymbol('proto.model.ContactResponse', null, global);
goog.exportSymbol('proto.model.CountryResponse', null, global);
goog.exportSymbol('proto.model.CurrencyResponse', null, global);
goog.exportSymbol('proto.model.DealListDocumentCargoItemResponse', null, global);
goog.exportSymbol('proto.model.DealListDocumentResponse', null, global);
goog.exportSymbol('proto.model.DealLoadStatus', null, global);
goog.exportSymbol('proto.model.DealOrdersTransporterDirectionInfoResponse', null, global);
goog.exportSymbol('proto.model.DealResponse', null, global);
goog.exportSymbol('proto.model.DealStatus', null, global);
goog.exportSymbol('proto.model.DealStatusCommentRecipientResponse', null, global);
goog.exportSymbol('proto.model.DealStatusCommentResponse', null, global);
goog.exportSymbol('proto.model.DealStatusCommentTemplateResponse', null, global);
goog.exportSymbol('proto.model.DealTransporterDirectionNoteResponse', null, global);
goog.exportSymbol('proto.model.DealType', null, global);
goog.exportSymbol('proto.model.DispatchDeliveryStatus', null, global);
goog.exportSymbol('proto.model.DispatchLoadingStatus', null, global);
goog.exportSymbol('proto.model.DocTypeResponse', null, global);
goog.exportSymbol('proto.model.DocTypeShortResponse', null, global);
goog.exportSymbol('proto.model.DriverDetails', null, global);
goog.exportSymbol('proto.model.ItemType', null, global);
goog.exportSymbol('proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse', null, global);
goog.exportSymbol('proto.model.JoinedOrderTransporterDirectionGroup', null, global);
goog.exportSymbol('proto.model.JoinedOrderTransporterDirectionOrderInfo', null, global);
goog.exportSymbol('proto.model.Model', null, global);
goog.exportSymbol('proto.model.NewOrderMessageResponse', null, global);
goog.exportSymbol('proto.model.OrderAddressDetails', null, global);
goog.exportSymbol('proto.model.OrderBrokerageServices', null, global);
goog.exportSymbol('proto.model.OrderContractResponse', null, global);
goog.exportSymbol('proto.model.OrderItemDetailedResponse', null, global);
goog.exportSymbol('proto.model.OrderItemResponse', null, global);
goog.exportSymbol('proto.model.OrderReportedProblemCulprit', null, global);
goog.exportSymbol('proto.model.OrderReportedProblemResponse', null, global);
goog.exportSymbol('proto.model.OrderReportedProblemTypeResponse', null, global);
goog.exportSymbol('proto.model.OrderResponse', null, global);
goog.exportSymbol('proto.model.OrderStorageFileResponse', null, global);
goog.exportSymbol('proto.model.OrderTransporterDirectionPriceInfoResponse', null, global);
goog.exportSymbol('proto.model.OrderTransporterDirectionResponse', null, global);
goog.exportSymbol('proto.model.PalletStandard', null, global);
goog.exportSymbol('proto.model.PassportResponse', null, global);
goog.exportSymbol('proto.model.StorageFileResponse', null, global);
goog.exportSymbol('proto.model.StorageFileTemplateResponse', null, global);
goog.exportSymbol('proto.model.TrailerTypeResponse', null, global);
goog.exportSymbol('proto.model.TransporterDirectionResponse', null, global);
goog.exportSymbol('proto.model.TransporterDriverResponse', null, global);
goog.exportSymbol('proto.model.TransporterResponse', null, global);
goog.exportSymbol('proto.model.TransporterTrailerResponse', null, global);
goog.exportSymbol('proto.model.TransporterTruckResponse', null, global);
goog.exportSymbol('proto.model.UserMetaResponse', null, global);
goog.exportSymbol('proto.model.UserResponse', null, global);
goog.exportSymbol('proto.model.UserRole', null, global);
goog.exportSymbol('proto.model.WeightUnit', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.AddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.AddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.AddressResponse.displayName = 'proto.model.AddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.BorderEntranceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.BorderEntranceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.BorderEntranceResponse.displayName = 'proto.model.BorderEntranceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.CargoTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.CargoTypeResponse.repeatedFields_, null);
};
goog.inherits(proto.model.CargoTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.CargoTypeResponse.displayName = 'proto.model.CargoTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.CargoTypeDocTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.CargoTypeDocTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.CargoTypeDocTypeResponse.displayName = 'proto.model.CargoTypeDocTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.CategoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.CategoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.CategoryResponse.displayName = 'proto.model.CategoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.CompanyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.CompanyResponse.repeatedFields_, null);
};
goog.inherits(proto.model.CompanyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.CompanyResponse.displayName = 'proto.model.CompanyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.CompanyBankResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.CompanyBankResponse.repeatedFields_, null);
};
goog.inherits(proto.model.CompanyBankResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.CompanyBankResponse.displayName = 'proto.model.CompanyBankResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.CompanyBankAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.CompanyBankAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.CompanyBankAccountResponse.displayName = 'proto.model.CompanyBankAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ContactResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.ContactResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ContactResponse.displayName = 'proto.model.ContactResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.CountryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.CountryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.CountryResponse.displayName = 'proto.model.CountryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.CurrencyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.CurrencyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.CurrencyResponse.displayName = 'proto.model.CurrencyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DealResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.DealResponse.repeatedFields_, null);
};
goog.inherits(proto.model.DealResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DealResponse.displayName = 'proto.model.DealResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DealListDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.DealListDocumentResponse.repeatedFields_, null);
};
goog.inherits(proto.model.DealListDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DealListDocumentResponse.displayName = 'proto.model.DealListDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DealListDocumentCargoItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.DealListDocumentCargoItemResponse.repeatedFields_, null);
};
goog.inherits(proto.model.DealListDocumentCargoItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DealListDocumentCargoItemResponse.displayName = 'proto.model.DealListDocumentCargoItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DealStatusCommentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.DealStatusCommentResponse.repeatedFields_, null);
};
goog.inherits(proto.model.DealStatusCommentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DealStatusCommentResponse.displayName = 'proto.model.DealStatusCommentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DealStatusCommentRecipientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.DealStatusCommentRecipientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DealStatusCommentRecipientResponse.displayName = 'proto.model.DealStatusCommentRecipientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DealStatusCommentTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.DealStatusCommentTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DealStatusCommentTemplateResponse.displayName = 'proto.model.DealStatusCommentTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DealTransporterDirectionNoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.DealTransporterDirectionNoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DealTransporterDirectionNoteResponse.displayName = 'proto.model.DealTransporterDirectionNoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DocTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.DocTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DocTypeResponse.displayName = 'proto.model.DocTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DocTypeShortResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.DocTypeShortResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DocTypeShortResponse.displayName = 'proto.model.DocTypeShortResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.OrderAddressDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.OrderAddressDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.OrderAddressDetails.displayName = 'proto.model.OrderAddressDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.OrderItemDetailedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.OrderItemDetailedResponse.repeatedFields_, null);
};
goog.inherits(proto.model.OrderItemDetailedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.OrderItemDetailedResponse.displayName = 'proto.model.OrderItemDetailedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.OrderItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.OrderItemResponse.repeatedFields_, null);
};
goog.inherits(proto.model.OrderItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.OrderItemResponse.displayName = 'proto.model.OrderItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.OrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.OrderResponse.repeatedFields_, null);
};
goog.inherits(proto.model.OrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.OrderResponse.displayName = 'proto.model.OrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.CheckedDocTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.CheckedDocTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.CheckedDocTypeResponse.displayName = 'proto.model.CheckedDocTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.BrokerageServicesCompanyDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.BrokerageServicesCompanyDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.BrokerageServicesCompanyDetails.displayName = 'proto.model.BrokerageServicesCompanyDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.OrderContractResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.OrderContractResponse.repeatedFields_, null);
};
goog.inherits(proto.model.OrderContractResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.OrderContractResponse.displayName = 'proto.model.OrderContractResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.NewOrderMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.NewOrderMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.NewOrderMessageResponse.displayName = 'proto.model.NewOrderMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.OrderReportedProblemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.OrderReportedProblemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.OrderReportedProblemResponse.displayName = 'proto.model.OrderReportedProblemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.OrderReportedProblemTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.OrderReportedProblemTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.OrderReportedProblemTypeResponse.displayName = 'proto.model.OrderReportedProblemTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.OrderStorageFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.OrderStorageFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.OrderStorageFileResponse.displayName = 'proto.model.OrderStorageFileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.OrderTransporterDirectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.OrderTransporterDirectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.OrderTransporterDirectionResponse.displayName = 'proto.model.OrderTransporterDirectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DealOrdersTransporterDirectionInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.DealOrdersTransporterDirectionInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DealOrdersTransporterDirectionInfoResponse.displayName = 'proto.model.DealOrdersTransporterDirectionInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.OrderTransporterDirectionPriceInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.OrderTransporterDirectionPriceInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.OrderTransporterDirectionPriceInfoResponse.displayName = 'proto.model.OrderTransporterDirectionPriceInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DriverDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.DriverDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DriverDetails.displayName = 'proto.model.DriverDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.JoinedOrderTransporterDirectionGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.JoinedOrderTransporterDirectionGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.JoinedOrderTransporterDirectionGroup.displayName = 'proto.model.JoinedOrderTransporterDirectionGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.displayName = 'proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.JoinedOrderTransporterDirectionOrderInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.JoinedOrderTransporterDirectionOrderInfo.displayName = 'proto.model.JoinedOrderTransporterDirectionOrderInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.PassportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.PassportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.PassportResponse.displayName = 'proto.model.PassportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.StorageFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.StorageFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.StorageFileResponse.displayName = 'proto.model.StorageFileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.StorageFileTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.StorageFileTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.StorageFileTemplateResponse.displayName = 'proto.model.StorageFileTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.TrailerTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.TrailerTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.TrailerTypeResponse.displayName = 'proto.model.TrailerTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.TransporterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.TransporterResponse.repeatedFields_, null);
};
goog.inherits(proto.model.TransporterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.TransporterResponse.displayName = 'proto.model.TransporterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.TransporterDirectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.TransporterDirectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.TransporterDirectionResponse.displayName = 'proto.model.TransporterDirectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.TransporterDriverResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.TransporterDriverResponse.repeatedFields_, null);
};
goog.inherits(proto.model.TransporterDriverResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.TransporterDriverResponse.displayName = 'proto.model.TransporterDriverResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.TransporterTrailerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.TransporterTrailerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.TransporterTrailerResponse.displayName = 'proto.model.TransporterTrailerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.TransporterTruckResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.TransporterTruckResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.TransporterTruckResponse.displayName = 'proto.model.TransporterTruckResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.UserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.UserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.UserResponse.displayName = 'proto.model.UserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.UserMetaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.UserMetaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.UserMetaResponse.displayName = 'proto.model.UserMetaResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.AddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.AddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.AddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.AddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    countryName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, ""),
    county: jspb.Message.getFieldWithDefault(msg, 6, ""),
    city: jspb.Message.getFieldWithDefault(msg, 7, ""),
    street: jspb.Message.getFieldWithDefault(msg, 8, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    houseNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    lat: jspb.Message.getFieldWithDefault(msg, 11, ""),
    lng: jspb.Message.getFieldWithDefault(msg, 12, ""),
    placeId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 14, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 17, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.AddressResponse}
 */
proto.model.AddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.AddressResponse;
  return proto.model.AddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.AddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.AddressResponse}
 */
proto.model.AddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCounty(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setHouseNumber(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLat(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLng(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceId(value);
      break;
    case 14:
      var value = /** @type {!proto.model.AddressProvider} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.AddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.AddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.AddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.AddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCounty();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHouseNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLat();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLng();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPlaceId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country_code = 3;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country_name = 4;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state = 5;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string county = 6;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getCounty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setCounty = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string city = 7;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string street = 8;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string postal_code = 9;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string house_number = 10;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getHouseNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setHouseNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string lat = 11;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getLat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setLat = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string lng = 12;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getLng = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setLng = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string place_id = 13;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getPlaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setPlaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional AddressProvider provider = 14;
 * @return {!proto.model.AddressProvider}
 */
proto.model.AddressResponse.prototype.getProvider = function() {
  return /** @type {!proto.model.AddressProvider} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.model.AddressProvider} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.AddressResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.AddressResponse} returns this
*/
proto.model.AddressResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.AddressResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.AddressResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.AddressResponse} returns this
*/
proto.model.AddressResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.AddressResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string created_by_id = 17;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string updated_by_id = 18;
 * @return {string}
 */
proto.model.AddressResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.AddressResponse} returns this
 */
proto.model.AddressResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.BorderEntranceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.BorderEntranceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.BorderEntranceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.BorderEntranceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 8, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.BorderEntranceResponse}
 */
proto.model.BorderEntranceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.BorderEntranceResponse;
  return proto.model.BorderEntranceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.BorderEntranceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.BorderEntranceResponse}
 */
proto.model.BorderEntranceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.BorderEntranceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.BorderEntranceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.BorderEntranceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.BorderEntranceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.BorderEntranceResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.BorderEntranceResponse} returns this
 */
proto.model.BorderEntranceResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.model.BorderEntranceResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.BorderEntranceResponse} returns this
 */
proto.model.BorderEntranceResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_active = 5;
 * @return {boolean}
 */
proto.model.BorderEntranceResponse.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.BorderEntranceResponse} returns this
 */
proto.model.BorderEntranceResponse.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.BorderEntranceResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.BorderEntranceResponse} returns this
*/
proto.model.BorderEntranceResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.BorderEntranceResponse} returns this
 */
proto.model.BorderEntranceResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.BorderEntranceResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.BorderEntranceResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.BorderEntranceResponse} returns this
*/
proto.model.BorderEntranceResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.BorderEntranceResponse} returns this
 */
proto.model.BorderEntranceResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.BorderEntranceResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string created_by_id = 8;
 * @return {string}
 */
proto.model.BorderEntranceResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.BorderEntranceResponse} returns this
 */
proto.model.BorderEntranceResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string updated_by_id = 9;
 * @return {string}
 */
proto.model.BorderEntranceResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.BorderEntranceResponse} returns this
 */
proto.model.BorderEntranceResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.CargoTypeResponse.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.CargoTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.CargoTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.CargoTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CargoTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    category: (f = msg.getCategory()) && proto.model.CategoryResponse.toObject(includeInstance, f),
    adr: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    ansaControl: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    trailerTypeId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    trailerType: (f = msg.getTrailerType()) && proto.model.TrailerTypeResponse.toObject(includeInstance, f),
    temperatureRegime: jspb.Message.getFieldWithDefault(msg, 9, ""),
    cargoTypeDocTypesList: jspb.Message.toObjectList(msg.getCargoTypeDocTypesList(),
    proto.model.CargoTypeDocTypeResponse.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 13, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.CargoTypeResponse}
 */
proto.model.CargoTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.CargoTypeResponse;
  return proto.model.CargoTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.CargoTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.CargoTypeResponse}
 */
proto.model.CargoTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 4:
      var value = new proto.model.CategoryResponse;
      reader.readMessage(value,proto.model.CategoryResponse.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdr(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnsaControl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeId(value);
      break;
    case 8:
      var value = new proto.model.TrailerTypeResponse;
      reader.readMessage(value,proto.model.TrailerTypeResponse.deserializeBinaryFromReader);
      msg.setTrailerType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemperatureRegime(value);
      break;
    case 10:
      var value = new proto.model.CargoTypeDocTypeResponse;
      reader.readMessage(value,proto.model.CargoTypeDocTypeResponse.deserializeBinaryFromReader);
      msg.addCargoTypeDocTypes(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.CargoTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.CargoTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.CargoTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CargoTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.model.CategoryResponse.serializeBinaryToWriter
    );
  }
  f = message.getAdr();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAnsaControl();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTrailerTypeId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTrailerType();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.model.TrailerTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getTemperatureRegime();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCargoTypeDocTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.model.CargoTypeDocTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.CargoTypeResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CargoTypeResponse} returns this
 */
proto.model.CargoTypeResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.model.CargoTypeResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CargoTypeResponse} returns this
 */
proto.model.CargoTypeResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category_id = 3;
 * @return {string}
 */
proto.model.CargoTypeResponse.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CargoTypeResponse} returns this
 */
proto.model.CargoTypeResponse.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CategoryResponse category = 4;
 * @return {?proto.model.CategoryResponse}
 */
proto.model.CargoTypeResponse.prototype.getCategory = function() {
  return /** @type{?proto.model.CategoryResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CategoryResponse, 4));
};


/**
 * @param {?proto.model.CategoryResponse|undefined} value
 * @return {!proto.model.CargoTypeResponse} returns this
*/
proto.model.CargoTypeResponse.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CargoTypeResponse} returns this
 */
proto.model.CargoTypeResponse.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CargoTypeResponse.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool adr = 5;
 * @return {boolean}
 */
proto.model.CargoTypeResponse.prototype.getAdr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.CargoTypeResponse} returns this
 */
proto.model.CargoTypeResponse.prototype.setAdr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool ansa_control = 6;
 * @return {boolean}
 */
proto.model.CargoTypeResponse.prototype.getAnsaControl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.CargoTypeResponse} returns this
 */
proto.model.CargoTypeResponse.prototype.setAnsaControl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string trailer_type_id = 7;
 * @return {string}
 */
proto.model.CargoTypeResponse.prototype.getTrailerTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CargoTypeResponse} returns this
 */
proto.model.CargoTypeResponse.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional TrailerTypeResponse trailer_type = 8;
 * @return {?proto.model.TrailerTypeResponse}
 */
proto.model.CargoTypeResponse.prototype.getTrailerType = function() {
  return /** @type{?proto.model.TrailerTypeResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TrailerTypeResponse, 8));
};


/**
 * @param {?proto.model.TrailerTypeResponse|undefined} value
 * @return {!proto.model.CargoTypeResponse} returns this
*/
proto.model.CargoTypeResponse.prototype.setTrailerType = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CargoTypeResponse} returns this
 */
proto.model.CargoTypeResponse.prototype.clearTrailerType = function() {
  return this.setTrailerType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CargoTypeResponse.prototype.hasTrailerType = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string temperature_regime = 9;
 * @return {string}
 */
proto.model.CargoTypeResponse.prototype.getTemperatureRegime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CargoTypeResponse} returns this
 */
proto.model.CargoTypeResponse.prototype.setTemperatureRegime = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated CargoTypeDocTypeResponse cargo_type_doc_types = 10;
 * @return {!Array<!proto.model.CargoTypeDocTypeResponse>}
 */
proto.model.CargoTypeResponse.prototype.getCargoTypeDocTypesList = function() {
  return /** @type{!Array<!proto.model.CargoTypeDocTypeResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.CargoTypeDocTypeResponse, 10));
};


/**
 * @param {!Array<!proto.model.CargoTypeDocTypeResponse>} value
 * @return {!proto.model.CargoTypeResponse} returns this
*/
proto.model.CargoTypeResponse.prototype.setCargoTypeDocTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.model.CargoTypeDocTypeResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.CargoTypeDocTypeResponse}
 */
proto.model.CargoTypeResponse.prototype.addCargoTypeDocTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.model.CargoTypeDocTypeResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.CargoTypeResponse} returns this
 */
proto.model.CargoTypeResponse.prototype.clearCargoTypeDocTypesList = function() {
  return this.setCargoTypeDocTypesList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.CargoTypeResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.CargoTypeResponse} returns this
*/
proto.model.CargoTypeResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CargoTypeResponse} returns this
 */
proto.model.CargoTypeResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CargoTypeResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.CargoTypeResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.CargoTypeResponse} returns this
*/
proto.model.CargoTypeResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CargoTypeResponse} returns this
 */
proto.model.CargoTypeResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CargoTypeResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string created_by_id = 13;
 * @return {string}
 */
proto.model.CargoTypeResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CargoTypeResponse} returns this
 */
proto.model.CargoTypeResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string updated_by_id = 14;
 * @return {string}
 */
proto.model.CargoTypeResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CargoTypeResponse} returns this
 */
proto.model.CargoTypeResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.CargoTypeDocTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.CargoTypeDocTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.CargoTypeDocTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CargoTypeDocTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cargoTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    docTypeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    docType: (f = msg.getDocType()) && proto.model.DocTypeShortResponse.toObject(includeInstance, f),
    isRequired: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.CargoTypeDocTypeResponse}
 */
proto.model.CargoTypeDocTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.CargoTypeDocTypeResponse;
  return proto.model.CargoTypeDocTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.CargoTypeDocTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.CargoTypeDocTypeResponse}
 */
proto.model.CargoTypeDocTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCargoTypeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocTypeId(value);
      break;
    case 4:
      var value = new proto.model.DocTypeShortResponse;
      reader.readMessage(value,proto.model.DocTypeShortResponse.deserializeBinaryFromReader);
      msg.setDocType(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.CargoTypeDocTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.CargoTypeDocTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.CargoTypeDocTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CargoTypeDocTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCargoTypeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocTypeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocType();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.model.DocTypeShortResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsRequired();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.CargoTypeDocTypeResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CargoTypeDocTypeResponse} returns this
 */
proto.model.CargoTypeDocTypeResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cargo_type_id = 2;
 * @return {string}
 */
proto.model.CargoTypeDocTypeResponse.prototype.getCargoTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CargoTypeDocTypeResponse} returns this
 */
proto.model.CargoTypeDocTypeResponse.prototype.setCargoTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string doc_type_id = 3;
 * @return {string}
 */
proto.model.CargoTypeDocTypeResponse.prototype.getDocTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CargoTypeDocTypeResponse} returns this
 */
proto.model.CargoTypeDocTypeResponse.prototype.setDocTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DocTypeShortResponse doc_type = 4;
 * @return {?proto.model.DocTypeShortResponse}
 */
proto.model.CargoTypeDocTypeResponse.prototype.getDocType = function() {
  return /** @type{?proto.model.DocTypeShortResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.DocTypeShortResponse, 4));
};


/**
 * @param {?proto.model.DocTypeShortResponse|undefined} value
 * @return {!proto.model.CargoTypeDocTypeResponse} returns this
*/
proto.model.CargoTypeDocTypeResponse.prototype.setDocType = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CargoTypeDocTypeResponse} returns this
 */
proto.model.CargoTypeDocTypeResponse.prototype.clearDocType = function() {
  return this.setDocType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CargoTypeDocTypeResponse.prototype.hasDocType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_required = 5;
 * @return {boolean}
 */
proto.model.CargoTypeDocTypeResponse.prototype.getIsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.CargoTypeDocTypeResponse} returns this
 */
proto.model.CargoTypeDocTypeResponse.prototype.setIsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.CategoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.CategoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.CategoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CategoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 5, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.CategoryResponse}
 */
proto.model.CategoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.CategoryResponse;
  return proto.model.CategoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.CategoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.CategoryResponse}
 */
proto.model.CategoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.CategoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.CategoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.CategoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CategoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.CategoryResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CategoryResponse} returns this
 */
proto.model.CategoryResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.model.CategoryResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CategoryResponse} returns this
 */
proto.model.CategoryResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.CategoryResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.CategoryResponse} returns this
*/
proto.model.CategoryResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CategoryResponse} returns this
 */
proto.model.CategoryResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CategoryResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.CategoryResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.CategoryResponse} returns this
*/
proto.model.CategoryResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CategoryResponse} returns this
 */
proto.model.CategoryResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CategoryResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string created_by_id = 5;
 * @return {string}
 */
proto.model.CategoryResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CategoryResponse} returns this
 */
proto.model.CategoryResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string updated_by_id = 6;
 * @return {string}
 */
proto.model.CategoryResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CategoryResponse} returns this
 */
proto.model.CategoryResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.CompanyResponse.repeatedFields_ = [14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.CompanyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.CompanyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.CompanyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CompanyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isExporter: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isTransporter: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isBroker: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isResident: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    vatCode: jspb.Message.getFieldWithDefault(msg, 10, 0),
    idno: jspb.Message.getFieldWithDefault(msg, 11, 0),
    fullAddress: jspb.Message.getFieldWithDefault(msg, 12, ""),
    banksList: jspb.Message.toObjectList(msg.getBanksList(),
    proto.model.CompanyBankResponse.toObject, includeInstance),
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    proto.model.ContactResponse.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 18, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.CompanyResponse}
 */
proto.model.CompanyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.CompanyResponse;
  return proto.model.CompanyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.CompanyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.CompanyResponse}
 */
proto.model.CompanyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExporter(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTransporter(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBroker(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsResident(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVatCode(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIdno(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullAddress(value);
      break;
    case 14:
      var value = new proto.model.CompanyBankResponse;
      reader.readMessage(value,proto.model.CompanyBankResponse.deserializeBinaryFromReader);
      msg.addBanks(value);
      break;
    case 15:
      var value = new proto.model.ContactResponse;
      reader.readMessage(value,proto.model.ContactResponse.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.CompanyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.CompanyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.CompanyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CompanyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsExporter();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsTransporter();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsBroker();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsResident();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getVatCode();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getIdno();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getFullAddress();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBanksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.model.CompanyBankResponse.serializeBinaryToWriter
    );
  }
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.model.ContactResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.CompanyResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.model.CompanyResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_exporter = 6;
 * @return {boolean}
 */
proto.model.CompanyResponse.prototype.getIsExporter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.setIsExporter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_transporter = 7;
 * @return {boolean}
 */
proto.model.CompanyResponse.prototype.getIsTransporter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.setIsTransporter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_broker = 8;
 * @return {boolean}
 */
proto.model.CompanyResponse.prototype.getIsBroker = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.setIsBroker = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_resident = 9;
 * @return {boolean}
 */
proto.model.CompanyResponse.prototype.getIsResident = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.setIsResident = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int64 vat_code = 10;
 * @return {number}
 */
proto.model.CompanyResponse.prototype.getVatCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.setVatCode = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 idno = 11;
 * @return {number}
 */
proto.model.CompanyResponse.prototype.getIdno = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.setIdno = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string full_address = 12;
 * @return {string}
 */
proto.model.CompanyResponse.prototype.getFullAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.setFullAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated CompanyBankResponse banks = 14;
 * @return {!Array<!proto.model.CompanyBankResponse>}
 */
proto.model.CompanyResponse.prototype.getBanksList = function() {
  return /** @type{!Array<!proto.model.CompanyBankResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.CompanyBankResponse, 14));
};


/**
 * @param {!Array<!proto.model.CompanyBankResponse>} value
 * @return {!proto.model.CompanyResponse} returns this
*/
proto.model.CompanyResponse.prototype.setBanksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.model.CompanyBankResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.CompanyBankResponse}
 */
proto.model.CompanyResponse.prototype.addBanks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.model.CompanyBankResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.clearBanksList = function() {
  return this.setBanksList([]);
};


/**
 * repeated ContactResponse contacts = 15;
 * @return {!Array<!proto.model.ContactResponse>}
 */
proto.model.CompanyResponse.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.model.ContactResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.ContactResponse, 15));
};


/**
 * @param {!Array<!proto.model.ContactResponse>} value
 * @return {!proto.model.CompanyResponse} returns this
*/
proto.model.CompanyResponse.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.model.ContactResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.ContactResponse}
 */
proto.model.CompanyResponse.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.model.ContactResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.CompanyResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.CompanyResponse} returns this
*/
proto.model.CompanyResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CompanyResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.CompanyResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.CompanyResponse} returns this
*/
proto.model.CompanyResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CompanyResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string created_by_id = 18;
 * @return {string}
 */
proto.model.CompanyResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string updated_by_id = 19;
 * @return {string}
 */
proto.model.CompanyResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyResponse} returns this
 */
proto.model.CompanyResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.CompanyBankResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.CompanyBankResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.CompanyBankResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.CompanyBankResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CompanyBankResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isForContract: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.model.CompanyBankAccountResponse.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 14, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.CompanyBankResponse}
 */
proto.model.CompanyBankResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.CompanyBankResponse;
  return proto.model.CompanyBankResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.CompanyBankResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.CompanyBankResponse}
 */
proto.model.CompanyBankResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsForContract(value);
      break;
    case 5:
      var value = new proto.model.CompanyBankAccountResponse;
      reader.readMessage(value,proto.model.CompanyBankAccountResponse.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.CompanyBankResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.CompanyBankResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.CompanyBankResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CompanyBankResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsForContract();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.model.CompanyBankAccountResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string company_id = 1;
 * @return {string}
 */
proto.model.CompanyBankResponse.prototype.getCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyBankResponse} returns this
 */
proto.model.CompanyBankResponse.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.model.CompanyBankResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyBankResponse} returns this
 */
proto.model.CompanyBankResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.model.CompanyBankResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyBankResponse} returns this
 */
proto.model.CompanyBankResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_for_contract = 4;
 * @return {boolean}
 */
proto.model.CompanyBankResponse.prototype.getIsForContract = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.CompanyBankResponse} returns this
 */
proto.model.CompanyBankResponse.prototype.setIsForContract = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated CompanyBankAccountResponse accounts = 5;
 * @return {!Array<!proto.model.CompanyBankAccountResponse>}
 */
proto.model.CompanyBankResponse.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.model.CompanyBankAccountResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.CompanyBankAccountResponse, 5));
};


/**
 * @param {!Array<!proto.model.CompanyBankAccountResponse>} value
 * @return {!proto.model.CompanyBankResponse} returns this
*/
proto.model.CompanyBankResponse.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.model.CompanyBankAccountResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.CompanyBankAccountResponse}
 */
proto.model.CompanyBankResponse.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.model.CompanyBankAccountResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.CompanyBankResponse} returns this
 */
proto.model.CompanyBankResponse.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.CompanyBankResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.CompanyBankResponse} returns this
*/
proto.model.CompanyBankResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CompanyBankResponse} returns this
 */
proto.model.CompanyBankResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CompanyBankResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.CompanyBankResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.CompanyBankResponse} returns this
*/
proto.model.CompanyBankResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CompanyBankResponse} returns this
 */
proto.model.CompanyBankResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CompanyBankResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string created_by_id = 14;
 * @return {string}
 */
proto.model.CompanyBankResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyBankResponse} returns this
 */
proto.model.CompanyBankResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string updated_by_id = 15;
 * @return {string}
 */
proto.model.CompanyBankResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyBankResponse} returns this
 */
proto.model.CompanyBankResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.CompanyBankAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.CompanyBankAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.CompanyBankAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CompanyBankAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyBankId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    iban: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isForContract: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 14, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.CompanyBankAccountResponse}
 */
proto.model.CompanyBankAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.CompanyBankAccountResponse;
  return proto.model.CompanyBankAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.CompanyBankAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.CompanyBankAccountResponse}
 */
proto.model.CompanyBankAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyBankId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIban(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsForContract(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.CompanyBankAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.CompanyBankAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.CompanyBankAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CompanyBankAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyBankId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIban();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsForContract();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string company_bank_id = 1;
 * @return {string}
 */
proto.model.CompanyBankAccountResponse.prototype.getCompanyBankId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyBankAccountResponse} returns this
 */
proto.model.CompanyBankAccountResponse.prototype.setCompanyBankId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.model.CompanyBankAccountResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyBankAccountResponse} returns this
 */
proto.model.CompanyBankAccountResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string iban = 3;
 * @return {string}
 */
proto.model.CompanyBankAccountResponse.prototype.getIban = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyBankAccountResponse} returns this
 */
proto.model.CompanyBankAccountResponse.prototype.setIban = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_for_contract = 4;
 * @return {boolean}
 */
proto.model.CompanyBankAccountResponse.prototype.getIsForContract = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.CompanyBankAccountResponse} returns this
 */
proto.model.CompanyBankAccountResponse.prototype.setIsForContract = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.CompanyBankAccountResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.CompanyBankAccountResponse} returns this
*/
proto.model.CompanyBankAccountResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CompanyBankAccountResponse} returns this
 */
proto.model.CompanyBankAccountResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CompanyBankAccountResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.CompanyBankAccountResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.CompanyBankAccountResponse} returns this
*/
proto.model.CompanyBankAccountResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CompanyBankAccountResponse} returns this
 */
proto.model.CompanyBankAccountResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CompanyBankAccountResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string created_by_id = 14;
 * @return {string}
 */
proto.model.CompanyBankAccountResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyBankAccountResponse} returns this
 */
proto.model.CompanyBankAccountResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string updated_by_id = 15;
 * @return {string}
 */
proto.model.CompanyBankAccountResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CompanyBankAccountResponse} returns this
 */
proto.model.CompanyBankAccountResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ContactResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ContactResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ContactResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ContactResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    companyId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    position: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 14, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ContactResponse}
 */
proto.model.ContactResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ContactResponse;
  return proto.model.ContactResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ContactResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ContactResponse}
 */
proto.model.ContactResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPosition(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ContactResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ContactResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ContactResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ContactResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompanyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPosition();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.ContactResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ContactResponse} returns this
 */
proto.model.ContactResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string company_id = 2;
 * @return {string}
 */
proto.model.ContactResponse.prototype.getCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ContactResponse} returns this
 */
proto.model.ContactResponse.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.model.ContactResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ContactResponse} returns this
 */
proto.model.ContactResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string position = 4;
 * @return {string}
 */
proto.model.ContactResponse.prototype.getPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ContactResponse} returns this
 */
proto.model.ContactResponse.prototype.setPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone = 5;
 * @return {string}
 */
proto.model.ContactResponse.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ContactResponse} returns this
 */
proto.model.ContactResponse.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.model.ContactResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ContactResponse} returns this
 */
proto.model.ContactResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.ContactResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.ContactResponse} returns this
*/
proto.model.ContactResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ContactResponse} returns this
 */
proto.model.ContactResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ContactResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.ContactResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.ContactResponse} returns this
*/
proto.model.ContactResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ContactResponse} returns this
 */
proto.model.ContactResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ContactResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string created_by_id = 14;
 * @return {string}
 */
proto.model.ContactResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ContactResponse} returns this
 */
proto.model.ContactResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string updated_by_id = 15;
 * @return {string}
 */
proto.model.ContactResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ContactResponse} returns this
 */
proto.model.ContactResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.CountryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.CountryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.CountryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CountryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cca2: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cca3: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ccn3: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 12, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.CountryResponse}
 */
proto.model.CountryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.CountryResponse;
  return proto.model.CountryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.CountryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.CountryResponse}
 */
proto.model.CountryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCca2(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCca3(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCcn3(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.CountryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.CountryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.CountryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CountryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCca2();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCca3();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCcn3();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.CountryResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CountryResponse} returns this
 */
proto.model.CountryResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.model.CountryResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CountryResponse} returns this
 */
proto.model.CountryResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cca2 = 3;
 * @return {string}
 */
proto.model.CountryResponse.prototype.getCca2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CountryResponse} returns this
 */
proto.model.CountryResponse.prototype.setCca2 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cca3 = 4;
 * @return {string}
 */
proto.model.CountryResponse.prototype.getCca3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CountryResponse} returns this
 */
proto.model.CountryResponse.prototype.setCca3 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ccn3 = 5;
 * @return {string}
 */
proto.model.CountryResponse.prototype.getCcn3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CountryResponse} returns this
 */
proto.model.CountryResponse.prototype.setCcn3 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.CountryResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.CountryResponse} returns this
*/
proto.model.CountryResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CountryResponse} returns this
 */
proto.model.CountryResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CountryResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.CountryResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.CountryResponse} returns this
*/
proto.model.CountryResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CountryResponse} returns this
 */
proto.model.CountryResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CountryResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string created_by_id = 12;
 * @return {string}
 */
proto.model.CountryResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CountryResponse} returns this
 */
proto.model.CountryResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string updated_by_id = 13;
 * @return {string}
 */
proto.model.CountryResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CountryResponse} returns this
 */
proto.model.CountryResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.CurrencyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.CurrencyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.CurrencyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CurrencyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 8, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.CurrencyResponse}
 */
proto.model.CurrencyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.CurrencyResponse;
  return proto.model.CurrencyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.CurrencyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.CurrencyResponse}
 */
proto.model.CurrencyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.CurrencyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.CurrencyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.CurrencyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CurrencyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.CurrencyResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CurrencyResponse} returns this
 */
proto.model.CurrencyResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.model.CurrencyResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CurrencyResponse} returns this
 */
proto.model.CurrencyResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.model.CurrencyResponse.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CurrencyResponse} returns this
 */
proto.model.CurrencyResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string symbol = 4;
 * @return {string}
 */
proto.model.CurrencyResponse.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CurrencyResponse} returns this
 */
proto.model.CurrencyResponse.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_active = 5;
 * @return {boolean}
 */
proto.model.CurrencyResponse.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.CurrencyResponse} returns this
 */
proto.model.CurrencyResponse.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.CurrencyResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.CurrencyResponse} returns this
*/
proto.model.CurrencyResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CurrencyResponse} returns this
 */
proto.model.CurrencyResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CurrencyResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.CurrencyResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.CurrencyResponse} returns this
*/
proto.model.CurrencyResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CurrencyResponse} returns this
 */
proto.model.CurrencyResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CurrencyResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string created_by_id = 8;
 * @return {string}
 */
proto.model.CurrencyResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CurrencyResponse} returns this
 */
proto.model.CurrencyResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string updated_by_id = 9;
 * @return {string}
 */
proto.model.CurrencyResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CurrencyResponse} returns this
 */
proto.model.CurrencyResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.DealResponse.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DealResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DealResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DealResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    assignedToDispatcherId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    assignedToDispatcher: (f = msg.getAssignedToDispatcher()) && proto.model.UserMetaResponse.toObject(includeInstance, f),
    assignedToId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    assignedTo: (f = msg.getAssignedTo()) && proto.model.UserMetaResponse.toObject(includeInstance, f),
    sequence: jspb.Message.getFieldWithDefault(msg, 12, 0),
    loadStatus: jspb.Message.getFieldWithDefault(msg, 13, 0),
    assignedToBrokerId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    assignedToBroker: (f = msg.getAssignedToBroker()) && proto.model.UserMetaResponse.toObject(includeInstance, f),
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.model.OrderResponse.toObject, includeInstance),
    createdById: jspb.Message.getFieldWithDefault(msg, 30, ""),
    createdBy: (f = msg.getCreatedBy()) && proto.model.UserMetaResponse.toObject(includeInstance, f),
    updatedById: jspb.Message.getFieldWithDefault(msg, 32, ""),
    updatedBy: (f = msg.getUpdatedBy()) && proto.model.UserMetaResponse.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DealResponse}
 */
proto.model.DealResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DealResponse;
  return proto.model.DealResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DealResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DealResponse}
 */
proto.model.DealResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.model.DealStatus} */ (reader.readEnum());
      msg.setDealStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedToDispatcherId(value);
      break;
    case 9:
      var value = new proto.model.UserMetaResponse;
      reader.readMessage(value,proto.model.UserMetaResponse.deserializeBinaryFromReader);
      msg.setAssignedToDispatcher(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedToId(value);
      break;
    case 11:
      var value = new proto.model.UserMetaResponse;
      reader.readMessage(value,proto.model.UserMetaResponse.deserializeBinaryFromReader);
      msg.setAssignedTo(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSequence(value);
      break;
    case 13:
      var value = /** @type {!proto.model.DealLoadStatus} */ (reader.readEnum());
      msg.setLoadStatus(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedToBrokerId(value);
      break;
    case 15:
      var value = new proto.model.UserMetaResponse;
      reader.readMessage(value,proto.model.UserMetaResponse.deserializeBinaryFromReader);
      msg.setAssignedToBroker(value);
      break;
    case 20:
      var value = new proto.model.OrderResponse;
      reader.readMessage(value,proto.model.OrderResponse.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 31:
      var value = new proto.model.UserMetaResponse;
      reader.readMessage(value,proto.model.UserMetaResponse.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    case 33:
      var value = new proto.model.UserMetaResponse;
      reader.readMessage(value,proto.model.UserMetaResponse.deserializeBinaryFromReader);
      msg.setUpdatedBy(value);
      break;
    case 34:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 35:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DealResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DealResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DealResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAssignedToDispatcherId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAssignedToDispatcher();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.model.UserMetaResponse.serializeBinaryToWriter
    );
  }
  f = message.getAssignedToId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAssignedTo();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.model.UserMetaResponse.serializeBinaryToWriter
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getLoadStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getAssignedToBrokerId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAssignedToBroker();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.model.UserMetaResponse.serializeBinaryToWriter
    );
  }
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.model.OrderResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.model.UserMetaResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getUpdatedBy();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.model.UserMetaResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.DealResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DealStatus deal_status = 2;
 * @return {!proto.model.DealStatus}
 */
proto.model.DealResponse.prototype.getDealStatus = function() {
  return /** @type {!proto.model.DealStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.model.DealStatus} value
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.setDealStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string assigned_to_dispatcher_id = 8;
 * @return {string}
 */
proto.model.DealResponse.prototype.getAssignedToDispatcherId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.setAssignedToDispatcherId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional UserMetaResponse assigned_to_dispatcher = 9;
 * @return {?proto.model.UserMetaResponse}
 */
proto.model.DealResponse.prototype.getAssignedToDispatcher = function() {
  return /** @type{?proto.model.UserMetaResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.UserMetaResponse, 9));
};


/**
 * @param {?proto.model.UserMetaResponse|undefined} value
 * @return {!proto.model.DealResponse} returns this
*/
proto.model.DealResponse.prototype.setAssignedToDispatcher = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.clearAssignedToDispatcher = function() {
  return this.setAssignedToDispatcher(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealResponse.prototype.hasAssignedToDispatcher = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string assigned_to_id = 10;
 * @return {string}
 */
proto.model.DealResponse.prototype.getAssignedToId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.setAssignedToId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional UserMetaResponse assigned_to = 11;
 * @return {?proto.model.UserMetaResponse}
 */
proto.model.DealResponse.prototype.getAssignedTo = function() {
  return /** @type{?proto.model.UserMetaResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.UserMetaResponse, 11));
};


/**
 * @param {?proto.model.UserMetaResponse|undefined} value
 * @return {!proto.model.DealResponse} returns this
*/
proto.model.DealResponse.prototype.setAssignedTo = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.clearAssignedTo = function() {
  return this.setAssignedTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealResponse.prototype.hasAssignedTo = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 sequence = 12;
 * @return {number}
 */
proto.model.DealResponse.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.setSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional DealLoadStatus load_status = 13;
 * @return {!proto.model.DealLoadStatus}
 */
proto.model.DealResponse.prototype.getLoadStatus = function() {
  return /** @type {!proto.model.DealLoadStatus} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.model.DealLoadStatus} value
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.setLoadStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional string assigned_to_broker_id = 14;
 * @return {string}
 */
proto.model.DealResponse.prototype.getAssignedToBrokerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.setAssignedToBrokerId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional UserMetaResponse assigned_to_broker = 15;
 * @return {?proto.model.UserMetaResponse}
 */
proto.model.DealResponse.prototype.getAssignedToBroker = function() {
  return /** @type{?proto.model.UserMetaResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.UserMetaResponse, 15));
};


/**
 * @param {?proto.model.UserMetaResponse|undefined} value
 * @return {!proto.model.DealResponse} returns this
*/
proto.model.DealResponse.prototype.setAssignedToBroker = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.clearAssignedToBroker = function() {
  return this.setAssignedToBroker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealResponse.prototype.hasAssignedToBroker = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated OrderResponse orders = 20;
 * @return {!Array<!proto.model.OrderResponse>}
 */
proto.model.DealResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.model.OrderResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.OrderResponse, 20));
};


/**
 * @param {!Array<!proto.model.OrderResponse>} value
 * @return {!proto.model.DealResponse} returns this
*/
proto.model.DealResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.model.OrderResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.OrderResponse}
 */
proto.model.DealResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.model.OrderResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};


/**
 * optional string created_by_id = 30;
 * @return {string}
 */
proto.model.DealResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional UserMetaResponse created_by = 31;
 * @return {?proto.model.UserMetaResponse}
 */
proto.model.DealResponse.prototype.getCreatedBy = function() {
  return /** @type{?proto.model.UserMetaResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.UserMetaResponse, 31));
};


/**
 * @param {?proto.model.UserMetaResponse|undefined} value
 * @return {!proto.model.DealResponse} returns this
*/
proto.model.DealResponse.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealResponse.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional string updated_by_id = 32;
 * @return {string}
 */
proto.model.DealResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional UserMetaResponse updated_by = 33;
 * @return {?proto.model.UserMetaResponse}
 */
proto.model.DealResponse.prototype.getUpdatedBy = function() {
  return /** @type{?proto.model.UserMetaResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.UserMetaResponse, 33));
};


/**
 * @param {?proto.model.UserMetaResponse|undefined} value
 * @return {!proto.model.DealResponse} returns this
*/
proto.model.DealResponse.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.clearUpdatedBy = function() {
  return this.setUpdatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealResponse.prototype.hasUpdatedBy = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 34;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.DealResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 34));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.DealResponse} returns this
*/
proto.model.DealResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 35;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.DealResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 35));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.DealResponse} returns this
*/
proto.model.DealResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealResponse} returns this
 */
proto.model.DealResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 35) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.DealListDocumentResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DealListDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DealListDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DealListDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealListDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    client: (f = msg.getClient()) && proto.model.CompanyResponse.toObject(includeInstance, f),
    source: (f = msg.getSource()) && proto.model.AddressResponse.toObject(includeInstance, f),
    destination: (f = msg.getDestination()) && proto.model.AddressResponse.toObject(includeInstance, f),
    cargoItemsList: jspb.Message.toObjectList(msg.getCargoItemsList(),
    proto.model.DealListDocumentCargoItemResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DealListDocumentResponse}
 */
proto.model.DealListDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DealListDocumentResponse;
  return proto.model.DealListDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DealListDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DealListDocumentResponse}
 */
proto.model.DealListDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = new proto.model.CompanyResponse;
      reader.readMessage(value,proto.model.CompanyResponse.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 3:
      var value = new proto.model.AddressResponse;
      reader.readMessage(value,proto.model.AddressResponse.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 4:
      var value = new proto.model.AddressResponse;
      reader.readMessage(value,proto.model.AddressResponse.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 5:
      var value = new proto.model.DealListDocumentCargoItemResponse;
      reader.readMessage(value,proto.model.DealListDocumentCargoItemResponse.deserializeBinaryFromReader);
      msg.addCargoItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DealListDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DealListDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DealListDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealListDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.model.CompanyResponse.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.model.AddressResponse.serializeBinaryToWriter
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.model.AddressResponse.serializeBinaryToWriter
    );
  }
  f = message.getCargoItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.model.DealListDocumentCargoItemResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.model.DealListDocumentResponse.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealListDocumentResponse} returns this
 */
proto.model.DealListDocumentResponse.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CompanyResponse client = 2;
 * @return {?proto.model.CompanyResponse}
 */
proto.model.DealListDocumentResponse.prototype.getClient = function() {
  return /** @type{?proto.model.CompanyResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CompanyResponse, 2));
};


/**
 * @param {?proto.model.CompanyResponse|undefined} value
 * @return {!proto.model.DealListDocumentResponse} returns this
*/
proto.model.DealListDocumentResponse.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealListDocumentResponse} returns this
 */
proto.model.DealListDocumentResponse.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealListDocumentResponse.prototype.hasClient = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AddressResponse source = 3;
 * @return {?proto.model.AddressResponse}
 */
proto.model.DealListDocumentResponse.prototype.getSource = function() {
  return /** @type{?proto.model.AddressResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.AddressResponse, 3));
};


/**
 * @param {?proto.model.AddressResponse|undefined} value
 * @return {!proto.model.DealListDocumentResponse} returns this
*/
proto.model.DealListDocumentResponse.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealListDocumentResponse} returns this
 */
proto.model.DealListDocumentResponse.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealListDocumentResponse.prototype.hasSource = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AddressResponse destination = 4;
 * @return {?proto.model.AddressResponse}
 */
proto.model.DealListDocumentResponse.prototype.getDestination = function() {
  return /** @type{?proto.model.AddressResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.AddressResponse, 4));
};


/**
 * @param {?proto.model.AddressResponse|undefined} value
 * @return {!proto.model.DealListDocumentResponse} returns this
*/
proto.model.DealListDocumentResponse.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealListDocumentResponse} returns this
 */
proto.model.DealListDocumentResponse.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealListDocumentResponse.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated DealListDocumentCargoItemResponse cargo_items = 5;
 * @return {!Array<!proto.model.DealListDocumentCargoItemResponse>}
 */
proto.model.DealListDocumentResponse.prototype.getCargoItemsList = function() {
  return /** @type{!Array<!proto.model.DealListDocumentCargoItemResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.DealListDocumentCargoItemResponse, 5));
};


/**
 * @param {!Array<!proto.model.DealListDocumentCargoItemResponse>} value
 * @return {!proto.model.DealListDocumentResponse} returns this
*/
proto.model.DealListDocumentResponse.prototype.setCargoItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.model.DealListDocumentCargoItemResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.DealListDocumentCargoItemResponse}
 */
proto.model.DealListDocumentResponse.prototype.addCargoItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.model.DealListDocumentCargoItemResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.DealListDocumentResponse} returns this
 */
proto.model.DealListDocumentResponse.prototype.clearCargoItemsList = function() {
  return this.setCargoItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.DealListDocumentCargoItemResponse.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DealListDocumentCargoItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DealListDocumentCargoItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DealListDocumentCargoItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealListDocumentCargoItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cargoItemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cargoTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cargoType: (f = msg.getCargoType()) && proto.model.CargoTypeResponse.toObject(includeInstance, f),
    categoryId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    category: (f = msg.getCategory()) && proto.model.CategoryResponse.toObject(includeInstance, f),
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto.model.OrderStorageFileResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DealListDocumentCargoItemResponse}
 */
proto.model.DealListDocumentCargoItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DealListDocumentCargoItemResponse;
  return proto.model.DealListDocumentCargoItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DealListDocumentCargoItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DealListDocumentCargoItemResponse}
 */
proto.model.DealListDocumentCargoItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCargoItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCargoTypeId(value);
      break;
    case 3:
      var value = new proto.model.CargoTypeResponse;
      reader.readMessage(value,proto.model.CargoTypeResponse.deserializeBinaryFromReader);
      msg.setCargoType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 5:
      var value = new proto.model.CategoryResponse;
      reader.readMessage(value,proto.model.CategoryResponse.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 6:
      var value = new proto.model.OrderStorageFileResponse;
      reader.readMessage(value,proto.model.OrderStorageFileResponse.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DealListDocumentCargoItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DealListDocumentCargoItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DealListDocumentCargoItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealListDocumentCargoItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCargoItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCargoTypeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCargoType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.model.CargoTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.model.CategoryResponse.serializeBinaryToWriter
    );
  }
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.model.OrderStorageFileResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string cargo_item_id = 1;
 * @return {string}
 */
proto.model.DealListDocumentCargoItemResponse.prototype.getCargoItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealListDocumentCargoItemResponse} returns this
 */
proto.model.DealListDocumentCargoItemResponse.prototype.setCargoItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cargo_type_id = 2;
 * @return {string}
 */
proto.model.DealListDocumentCargoItemResponse.prototype.getCargoTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealListDocumentCargoItemResponse} returns this
 */
proto.model.DealListDocumentCargoItemResponse.prototype.setCargoTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CargoTypeResponse cargo_type = 3;
 * @return {?proto.model.CargoTypeResponse}
 */
proto.model.DealListDocumentCargoItemResponse.prototype.getCargoType = function() {
  return /** @type{?proto.model.CargoTypeResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CargoTypeResponse, 3));
};


/**
 * @param {?proto.model.CargoTypeResponse|undefined} value
 * @return {!proto.model.DealListDocumentCargoItemResponse} returns this
*/
proto.model.DealListDocumentCargoItemResponse.prototype.setCargoType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealListDocumentCargoItemResponse} returns this
 */
proto.model.DealListDocumentCargoItemResponse.prototype.clearCargoType = function() {
  return this.setCargoType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealListDocumentCargoItemResponse.prototype.hasCargoType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string category_id = 4;
 * @return {string}
 */
proto.model.DealListDocumentCargoItemResponse.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealListDocumentCargoItemResponse} returns this
 */
proto.model.DealListDocumentCargoItemResponse.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional CategoryResponse category = 5;
 * @return {?proto.model.CategoryResponse}
 */
proto.model.DealListDocumentCargoItemResponse.prototype.getCategory = function() {
  return /** @type{?proto.model.CategoryResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CategoryResponse, 5));
};


/**
 * @param {?proto.model.CategoryResponse|undefined} value
 * @return {!proto.model.DealListDocumentCargoItemResponse} returns this
*/
proto.model.DealListDocumentCargoItemResponse.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealListDocumentCargoItemResponse} returns this
 */
proto.model.DealListDocumentCargoItemResponse.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealListDocumentCargoItemResponse.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated OrderStorageFileResponse files = 6;
 * @return {!Array<!proto.model.OrderStorageFileResponse>}
 */
proto.model.DealListDocumentCargoItemResponse.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.model.OrderStorageFileResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.OrderStorageFileResponse, 6));
};


/**
 * @param {!Array<!proto.model.OrderStorageFileResponse>} value
 * @return {!proto.model.DealListDocumentCargoItemResponse} returns this
*/
proto.model.DealListDocumentCargoItemResponse.prototype.setFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.model.OrderStorageFileResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.OrderStorageFileResponse}
 */
proto.model.DealListDocumentCargoItemResponse.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.model.OrderStorageFileResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.DealListDocumentCargoItemResponse} returns this
 */
proto.model.DealListDocumentCargoItemResponse.prototype.clearFilesList = function() {
  return this.setFilesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.DealStatusCommentResponse.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DealStatusCommentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DealStatusCommentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DealStatusCommentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealStatusCommentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    locationLat: jspb.Message.getFieldWithDefault(msg, 4, ""),
    locationLng: jspb.Message.getFieldWithDefault(msg, 5, ""),
    locationLabel: jspb.Message.getFieldWithDefault(msg, 6, ""),
    remdistKm: jspb.Message.getFieldWithDefault(msg, 7, ""),
    remdistLabel: jspb.Message.getFieldWithDefault(msg, 8, ""),
    recipientsList: jspb.Message.toObjectList(msg.getRecipientsList(),
    proto.model.DealStatusCommentRecipientResponse.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 14, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DealStatusCommentResponse}
 */
proto.model.DealStatusCommentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DealStatusCommentResponse;
  return proto.model.DealStatusCommentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DealStatusCommentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DealStatusCommentResponse}
 */
proto.model.DealStatusCommentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationLat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationLng(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationLabel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemdistKm(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemdistLabel(value);
      break;
    case 9:
      var value = new proto.model.DealStatusCommentRecipientResponse;
      reader.readMessage(value,proto.model.DealStatusCommentRecipientResponse.deserializeBinaryFromReader);
      msg.addRecipients(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DealStatusCommentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DealStatusCommentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DealStatusCommentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealStatusCommentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocationLat();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLocationLng();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLocationLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRemdistKm();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRemdistLabel();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRecipientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.model.DealStatusCommentRecipientResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.DealStatusCommentResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentResponse} returns this
 */
proto.model.DealStatusCommentResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.model.DealStatusCommentResponse.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentResponse} returns this
 */
proto.model.DealStatusCommentResponse.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.model.DealStatusCommentResponse.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentResponse} returns this
 */
proto.model.DealStatusCommentResponse.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string location_lat = 4;
 * @return {string}
 */
proto.model.DealStatusCommentResponse.prototype.getLocationLat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentResponse} returns this
 */
proto.model.DealStatusCommentResponse.prototype.setLocationLat = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string location_lng = 5;
 * @return {string}
 */
proto.model.DealStatusCommentResponse.prototype.getLocationLng = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentResponse} returns this
 */
proto.model.DealStatusCommentResponse.prototype.setLocationLng = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string location_label = 6;
 * @return {string}
 */
proto.model.DealStatusCommentResponse.prototype.getLocationLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentResponse} returns this
 */
proto.model.DealStatusCommentResponse.prototype.setLocationLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string remdist_km = 7;
 * @return {string}
 */
proto.model.DealStatusCommentResponse.prototype.getRemdistKm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentResponse} returns this
 */
proto.model.DealStatusCommentResponse.prototype.setRemdistKm = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string remdist_label = 8;
 * @return {string}
 */
proto.model.DealStatusCommentResponse.prototype.getRemdistLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentResponse} returns this
 */
proto.model.DealStatusCommentResponse.prototype.setRemdistLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated DealStatusCommentRecipientResponse recipients = 9;
 * @return {!Array<!proto.model.DealStatusCommentRecipientResponse>}
 */
proto.model.DealStatusCommentResponse.prototype.getRecipientsList = function() {
  return /** @type{!Array<!proto.model.DealStatusCommentRecipientResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.DealStatusCommentRecipientResponse, 9));
};


/**
 * @param {!Array<!proto.model.DealStatusCommentRecipientResponse>} value
 * @return {!proto.model.DealStatusCommentResponse} returns this
*/
proto.model.DealStatusCommentResponse.prototype.setRecipientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.model.DealStatusCommentRecipientResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.DealStatusCommentRecipientResponse}
 */
proto.model.DealStatusCommentResponse.prototype.addRecipients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.model.DealStatusCommentRecipientResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.DealStatusCommentResponse} returns this
 */
proto.model.DealStatusCommentResponse.prototype.clearRecipientsList = function() {
  return this.setRecipientsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.DealStatusCommentResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.DealStatusCommentResponse} returns this
*/
proto.model.DealStatusCommentResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealStatusCommentResponse} returns this
 */
proto.model.DealStatusCommentResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealStatusCommentResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.DealStatusCommentResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.DealStatusCommentResponse} returns this
*/
proto.model.DealStatusCommentResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealStatusCommentResponse} returns this
 */
proto.model.DealStatusCommentResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealStatusCommentResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string created_by_id = 14;
 * @return {string}
 */
proto.model.DealStatusCommentResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentResponse} returns this
 */
proto.model.DealStatusCommentResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string updated_by_id = 15;
 * @return {string}
 */
proto.model.DealStatusCommentResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentResponse} returns this
 */
proto.model.DealStatusCommentResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DealStatusCommentRecipientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DealStatusCommentRecipientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DealStatusCommentRecipientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealStatusCommentRecipientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    company: (f = msg.getCompany()) && proto.model.CompanyResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DealStatusCommentRecipientResponse}
 */
proto.model.DealStatusCommentRecipientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DealStatusCommentRecipientResponse;
  return proto.model.DealStatusCommentRecipientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DealStatusCommentRecipientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DealStatusCommentRecipientResponse}
 */
proto.model.DealStatusCommentRecipientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyId(value);
      break;
    case 2:
      var value = new proto.model.CompanyResponse;
      reader.readMessage(value,proto.model.CompanyResponse.deserializeBinaryFromReader);
      msg.setCompany(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DealStatusCommentRecipientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DealStatusCommentRecipientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DealStatusCommentRecipientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealStatusCommentRecipientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompany();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.model.CompanyResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string company_id = 1;
 * @return {string}
 */
proto.model.DealStatusCommentRecipientResponse.prototype.getCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentRecipientResponse} returns this
 */
proto.model.DealStatusCommentRecipientResponse.prototype.setCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CompanyResponse company = 2;
 * @return {?proto.model.CompanyResponse}
 */
proto.model.DealStatusCommentRecipientResponse.prototype.getCompany = function() {
  return /** @type{?proto.model.CompanyResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CompanyResponse, 2));
};


/**
 * @param {?proto.model.CompanyResponse|undefined} value
 * @return {!proto.model.DealStatusCommentRecipientResponse} returns this
*/
proto.model.DealStatusCommentRecipientResponse.prototype.setCompany = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealStatusCommentRecipientResponse} returns this
 */
proto.model.DealStatusCommentRecipientResponse.prototype.clearCompany = function() {
  return this.setCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealStatusCommentRecipientResponse.prototype.hasCompany = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DealStatusCommentTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DealStatusCommentTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DealStatusCommentTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealStatusCommentTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 14, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DealStatusCommentTemplateResponse}
 */
proto.model.DealStatusCommentTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DealStatusCommentTemplateResponse;
  return proto.model.DealStatusCommentTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DealStatusCommentTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DealStatusCommentTemplateResponse}
 */
proto.model.DealStatusCommentTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DealStatusCommentTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DealStatusCommentTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DealStatusCommentTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealStatusCommentTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.DealStatusCommentTemplateResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentTemplateResponse} returns this
 */
proto.model.DealStatusCommentTemplateResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.model.DealStatusCommentTemplateResponse.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentTemplateResponse} returns this
 */
proto.model.DealStatusCommentTemplateResponse.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.DealStatusCommentTemplateResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.DealStatusCommentTemplateResponse} returns this
*/
proto.model.DealStatusCommentTemplateResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealStatusCommentTemplateResponse} returns this
 */
proto.model.DealStatusCommentTemplateResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealStatusCommentTemplateResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.DealStatusCommentTemplateResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.DealStatusCommentTemplateResponse} returns this
*/
proto.model.DealStatusCommentTemplateResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealStatusCommentTemplateResponse} returns this
 */
proto.model.DealStatusCommentTemplateResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealStatusCommentTemplateResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string created_by_id = 14;
 * @return {string}
 */
proto.model.DealStatusCommentTemplateResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentTemplateResponse} returns this
 */
proto.model.DealStatusCommentTemplateResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string updated_by_id = 15;
 * @return {string}
 */
proto.model.DealStatusCommentTemplateResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealStatusCommentTemplateResponse} returns this
 */
proto.model.DealStatusCommentTemplateResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DealTransporterDirectionNoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DealTransporterDirectionNoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealTransporterDirectionNoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    message: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 8, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DealTransporterDirectionNoteResponse}
 */
proto.model.DealTransporterDirectionNoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DealTransporterDirectionNoteResponse;
  return proto.model.DealTransporterDirectionNoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DealTransporterDirectionNoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DealTransporterDirectionNoteResponse}
 */
proto.model.DealTransporterDirectionNoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DealTransporterDirectionNoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DealTransporterDirectionNoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealTransporterDirectionNoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealTransporterDirectionNoteResponse} returns this
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transporter_direction_id = 2;
 * @return {string}
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealTransporterDirectionNoteResponse} returns this
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealTransporterDirectionNoteResponse} returns this
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealTransporterDirectionNoteResponse} returns this
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.DealTransporterDirectionNoteResponse} returns this
*/
proto.model.DealTransporterDirectionNoteResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealTransporterDirectionNoteResponse} returns this
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.DealTransporterDirectionNoteResponse} returns this
*/
proto.model.DealTransporterDirectionNoteResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealTransporterDirectionNoteResponse} returns this
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string created_by_id = 8;
 * @return {string}
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealTransporterDirectionNoteResponse} returns this
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string updated_by_id = 9;
 * @return {string}
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealTransporterDirectionNoteResponse} returns this
 */
proto.model.DealTransporterDirectionNoteResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DocTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DocTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DocTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DocTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 6, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DocTypeResponse}
 */
proto.model.DocTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DocTypeResponse;
  return proto.model.DocTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DocTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DocTypeResponse}
 */
proto.model.DocTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DocTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DocTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DocTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DocTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.DocTypeResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DocTypeResponse} returns this
 */
proto.model.DocTypeResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.model.DocTypeResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DocTypeResponse} returns this
 */
proto.model.DocTypeResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 3;
 * @return {string}
 */
proto.model.DocTypeResponse.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DocTypeResponse} returns this
 */
proto.model.DocTypeResponse.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.DocTypeResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.DocTypeResponse} returns this
*/
proto.model.DocTypeResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DocTypeResponse} returns this
 */
proto.model.DocTypeResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DocTypeResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.DocTypeResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.DocTypeResponse} returns this
*/
proto.model.DocTypeResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DocTypeResponse} returns this
 */
proto.model.DocTypeResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DocTypeResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string created_by_id = 6;
 * @return {string}
 */
proto.model.DocTypeResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DocTypeResponse} returns this
 */
proto.model.DocTypeResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string updated_by_id = 7;
 * @return {string}
 */
proto.model.DocTypeResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DocTypeResponse} returns this
 */
proto.model.DocTypeResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DocTypeShortResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DocTypeShortResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DocTypeShortResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DocTypeShortResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DocTypeShortResponse}
 */
proto.model.DocTypeShortResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DocTypeShortResponse;
  return proto.model.DocTypeShortResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DocTypeShortResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DocTypeShortResponse}
 */
proto.model.DocTypeShortResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DocTypeShortResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DocTypeShortResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DocTypeShortResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DocTypeShortResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.DocTypeShortResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DocTypeShortResponse} returns this
 */
proto.model.DocTypeShortResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.model.DocTypeShortResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DocTypeShortResponse} returns this
 */
proto.model.DocTypeShortResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 3;
 * @return {string}
 */
proto.model.DocTypeShortResponse.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DocTypeShortResponse} returns this
 */
proto.model.DocTypeShortResponse.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.OrderAddressDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.model.OrderAddressDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.OrderAddressDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderAddressDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.OrderAddressDetails}
 */
proto.model.OrderAddressDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.OrderAddressDetails;
  return proto.model.OrderAddressDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.OrderAddressDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.OrderAddressDetails}
 */
proto.model.OrderAddressDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.OrderAddressDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.OrderAddressDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.OrderAddressDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderAddressDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.model.OrderAddressDetails.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderAddressDetails} returns this
 */
proto.model.OrderAddressDetails.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.model.OrderAddressDetails.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderAddressDetails} returns this
 */
proto.model.OrderAddressDetails.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.model.OrderAddressDetails.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderAddressDetails} returns this
 */
proto.model.OrderAddressDetails.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.OrderItemDetailedResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.OrderItemDetailedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.OrderItemDetailedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.OrderItemDetailedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderItemDetailedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    client: (f = msg.getClient()) && proto.model.CompanyResponse.toObject(includeInstance, f),
    exporter: (f = msg.getExporter()) && proto.model.CompanyResponse.toObject(includeInstance, f),
    cargoList: jspb.Message.toObjectList(msg.getCargoList(),
    proto.model.OrderItemResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.OrderItemDetailedResponse}
 */
proto.model.OrderItemDetailedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.OrderItemDetailedResponse;
  return proto.model.OrderItemDetailedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.OrderItemDetailedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.OrderItemDetailedResponse}
 */
proto.model.OrderItemDetailedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.CompanyResponse;
      reader.readMessage(value,proto.model.CompanyResponse.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 2:
      var value = new proto.model.CompanyResponse;
      reader.readMessage(value,proto.model.CompanyResponse.deserializeBinaryFromReader);
      msg.setExporter(value);
      break;
    case 5:
      var value = new proto.model.OrderItemResponse;
      reader.readMessage(value,proto.model.OrderItemResponse.deserializeBinaryFromReader);
      msg.addCargo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.OrderItemDetailedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.OrderItemDetailedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.OrderItemDetailedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderItemDetailedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.model.CompanyResponse.serializeBinaryToWriter
    );
  }
  f = message.getExporter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.model.CompanyResponse.serializeBinaryToWriter
    );
  }
  f = message.getCargoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.model.OrderItemResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional CompanyResponse client = 1;
 * @return {?proto.model.CompanyResponse}
 */
proto.model.OrderItemDetailedResponse.prototype.getClient = function() {
  return /** @type{?proto.model.CompanyResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CompanyResponse, 1));
};


/**
 * @param {?proto.model.CompanyResponse|undefined} value
 * @return {!proto.model.OrderItemDetailedResponse} returns this
*/
proto.model.OrderItemDetailedResponse.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderItemDetailedResponse} returns this
 */
proto.model.OrderItemDetailedResponse.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderItemDetailedResponse.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CompanyResponse exporter = 2;
 * @return {?proto.model.CompanyResponse}
 */
proto.model.OrderItemDetailedResponse.prototype.getExporter = function() {
  return /** @type{?proto.model.CompanyResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CompanyResponse, 2));
};


/**
 * @param {?proto.model.CompanyResponse|undefined} value
 * @return {!proto.model.OrderItemDetailedResponse} returns this
*/
proto.model.OrderItemDetailedResponse.prototype.setExporter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderItemDetailedResponse} returns this
 */
proto.model.OrderItemDetailedResponse.prototype.clearExporter = function() {
  return this.setExporter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderItemDetailedResponse.prototype.hasExporter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated OrderItemResponse cargo = 5;
 * @return {!Array<!proto.model.OrderItemResponse>}
 */
proto.model.OrderItemDetailedResponse.prototype.getCargoList = function() {
  return /** @type{!Array<!proto.model.OrderItemResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.OrderItemResponse, 5));
};


/**
 * @param {!Array<!proto.model.OrderItemResponse>} value
 * @return {!proto.model.OrderItemDetailedResponse} returns this
*/
proto.model.OrderItemDetailedResponse.prototype.setCargoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.model.OrderItemResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.OrderItemResponse}
 */
proto.model.OrderItemDetailedResponse.prototype.addCargo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.model.OrderItemResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.OrderItemDetailedResponse} returns this
 */
proto.model.OrderItemDetailedResponse.prototype.clearCargoList = function() {
  return this.setCargoList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.OrderItemResponse.repeatedFields_ = [21,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.OrderItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.OrderItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.OrderItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cargoTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cargoType: (f = msg.getCargoType()) && proto.model.CargoTypeResponse.toObject(includeInstance, f),
    categoryId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    category: (f = msg.getCategory()) && proto.model.CategoryResponse.toObject(includeInstance, f),
    adr: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    ansaControl: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    trailerTypeId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    trailerType: (f = msg.getTrailerType()) && proto.model.TrailerTypeResponse.toObject(includeInstance, f),
    temperatureRegime: jspb.Message.getFieldWithDefault(msg, 10, ""),
    itemType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    palletStandard: jspb.Message.getFieldWithDefault(msg, 12, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 13, 0),
    length: jspb.Message.getFieldWithDefault(msg, 14, 0),
    height: jspb.Message.getFieldWithDefault(msg, 15, 0),
    width: jspb.Message.getFieldWithDefault(msg, 16, 0),
    weightUnit: jspb.Message.getFieldWithDefault(msg, 17, 0),
    weight: jspb.Message.getFieldWithDefault(msg, 18, ""),
    volume: jspb.Message.getFieldWithDefault(msg, 19, ""),
    sideLength: jspb.Message.getFieldWithDefault(msg, 20, ""),
    checkedDocTypesList: jspb.Message.toObjectList(msg.getCheckedDocTypesList(),
    proto.model.CheckedDocTypeResponse.toObject, includeInstance),
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.model.OrderStorageFileResponse.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 27, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 28, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.OrderItemResponse}
 */
proto.model.OrderItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.OrderItemResponse;
  return proto.model.OrderItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.OrderItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.OrderItemResponse}
 */
proto.model.OrderItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCargoTypeId(value);
      break;
    case 3:
      var value = new proto.model.CargoTypeResponse;
      reader.readMessage(value,proto.model.CargoTypeResponse.deserializeBinaryFromReader);
      msg.setCargoType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 5:
      var value = new proto.model.CategoryResponse;
      reader.readMessage(value,proto.model.CategoryResponse.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdr(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAnsaControl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerTypeId(value);
      break;
    case 9:
      var value = new proto.model.TrailerTypeResponse;
      reader.readMessage(value,proto.model.TrailerTypeResponse.deserializeBinaryFromReader);
      msg.setTrailerType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemperatureRegime(value);
      break;
    case 11:
      var value = /** @type {!proto.model.ItemType} */ (reader.readEnum());
      msg.setItemType(value);
      break;
    case 12:
      var value = /** @type {!proto.model.PalletStandard} */ (reader.readEnum());
      msg.setPalletStandard(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLength(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 17:
      var value = /** @type {!proto.model.WeightUnit} */ (reader.readEnum());
      msg.setWeightUnit(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeight(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolume(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSideLength(value);
      break;
    case 21:
      var value = new proto.model.CheckedDocTypeResponse;
      reader.readMessage(value,proto.model.CheckedDocTypeResponse.deserializeBinaryFromReader);
      msg.addCheckedDocTypes(value);
      break;
    case 22:
      var value = new proto.model.OrderStorageFileResponse;
      reader.readMessage(value,proto.model.OrderStorageFileResponse.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    case 25:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 26:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.OrderItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.OrderItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.OrderItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCargoTypeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCargoType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.model.CargoTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.model.CategoryResponse.serializeBinaryToWriter
    );
  }
  f = message.getAdr();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAnsaControl();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTrailerTypeId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTrailerType();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.model.TrailerTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getTemperatureRegime();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getItemType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getPalletStandard();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getLength();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getWeightUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getWeight();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getVolume();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSideLength();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCheckedDocTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.model.CheckedDocTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.model.OrderStorageFileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.OrderItemResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cargo_type_id = 2;
 * @return {string}
 */
proto.model.OrderItemResponse.prototype.getCargoTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setCargoTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional CargoTypeResponse cargo_type = 3;
 * @return {?proto.model.CargoTypeResponse}
 */
proto.model.OrderItemResponse.prototype.getCargoType = function() {
  return /** @type{?proto.model.CargoTypeResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CargoTypeResponse, 3));
};


/**
 * @param {?proto.model.CargoTypeResponse|undefined} value
 * @return {!proto.model.OrderItemResponse} returns this
*/
proto.model.OrderItemResponse.prototype.setCargoType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.clearCargoType = function() {
  return this.setCargoType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderItemResponse.prototype.hasCargoType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string category_id = 4;
 * @return {string}
 */
proto.model.OrderItemResponse.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional CategoryResponse category = 5;
 * @return {?proto.model.CategoryResponse}
 */
proto.model.OrderItemResponse.prototype.getCategory = function() {
  return /** @type{?proto.model.CategoryResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CategoryResponse, 5));
};


/**
 * @param {?proto.model.CategoryResponse|undefined} value
 * @return {!proto.model.OrderItemResponse} returns this
*/
proto.model.OrderItemResponse.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderItemResponse.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool adr = 6;
 * @return {boolean}
 */
proto.model.OrderItemResponse.prototype.getAdr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setAdr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool ansa_control = 7;
 * @return {boolean}
 */
proto.model.OrderItemResponse.prototype.getAnsaControl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setAnsaControl = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string trailer_type_id = 8;
 * @return {string}
 */
proto.model.OrderItemResponse.prototype.getTrailerTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setTrailerTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional TrailerTypeResponse trailer_type = 9;
 * @return {?proto.model.TrailerTypeResponse}
 */
proto.model.OrderItemResponse.prototype.getTrailerType = function() {
  return /** @type{?proto.model.TrailerTypeResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TrailerTypeResponse, 9));
};


/**
 * @param {?proto.model.TrailerTypeResponse|undefined} value
 * @return {!proto.model.OrderItemResponse} returns this
*/
proto.model.OrderItemResponse.prototype.setTrailerType = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.clearTrailerType = function() {
  return this.setTrailerType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderItemResponse.prototype.hasTrailerType = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string temperature_regime = 10;
 * @return {string}
 */
proto.model.OrderItemResponse.prototype.getTemperatureRegime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setTemperatureRegime = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional ItemType item_type = 11;
 * @return {!proto.model.ItemType}
 */
proto.model.OrderItemResponse.prototype.getItemType = function() {
  return /** @type {!proto.model.ItemType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.model.ItemType} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setItemType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional PalletStandard pallet_standard = 12;
 * @return {!proto.model.PalletStandard}
 */
proto.model.OrderItemResponse.prototype.getPalletStandard = function() {
  return /** @type {!proto.model.PalletStandard} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.model.PalletStandard} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setPalletStandard = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional int32 quantity = 13;
 * @return {number}
 */
proto.model.OrderItemResponse.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 length = 14;
 * @return {number}
 */
proto.model.OrderItemResponse.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 height = 15;
 * @return {number}
 */
proto.model.OrderItemResponse.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 width = 16;
 * @return {number}
 */
proto.model.OrderItemResponse.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional WeightUnit weight_unit = 17;
 * @return {!proto.model.WeightUnit}
 */
proto.model.OrderItemResponse.prototype.getWeightUnit = function() {
  return /** @type {!proto.model.WeightUnit} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.model.WeightUnit} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setWeightUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional string weight = 18;
 * @return {string}
 */
proto.model.OrderItemResponse.prototype.getWeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setWeight = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string volume = 19;
 * @return {string}
 */
proto.model.OrderItemResponse.prototype.getVolume = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setVolume = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string side_length = 20;
 * @return {string}
 */
proto.model.OrderItemResponse.prototype.getSideLength = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setSideLength = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * repeated CheckedDocTypeResponse checked_doc_types = 21;
 * @return {!Array<!proto.model.CheckedDocTypeResponse>}
 */
proto.model.OrderItemResponse.prototype.getCheckedDocTypesList = function() {
  return /** @type{!Array<!proto.model.CheckedDocTypeResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.CheckedDocTypeResponse, 21));
};


/**
 * @param {!Array<!proto.model.CheckedDocTypeResponse>} value
 * @return {!proto.model.OrderItemResponse} returns this
*/
proto.model.OrderItemResponse.prototype.setCheckedDocTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.model.CheckedDocTypeResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.CheckedDocTypeResponse}
 */
proto.model.OrderItemResponse.prototype.addCheckedDocTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.model.CheckedDocTypeResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.clearCheckedDocTypesList = function() {
  return this.setCheckedDocTypesList([]);
};


/**
 * repeated OrderStorageFileResponse documents = 22;
 * @return {!Array<!proto.model.OrderStorageFileResponse>}
 */
proto.model.OrderItemResponse.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.model.OrderStorageFileResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.OrderStorageFileResponse, 22));
};


/**
 * @param {!Array<!proto.model.OrderStorageFileResponse>} value
 * @return {!proto.model.OrderItemResponse} returns this
*/
proto.model.OrderItemResponse.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.model.OrderStorageFileResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.OrderStorageFileResponse}
 */
proto.model.OrderItemResponse.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.model.OrderStorageFileResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 25;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderItemResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 25));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderItemResponse} returns this
*/
proto.model.OrderItemResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderItemResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 26;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderItemResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 26));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderItemResponse} returns this
*/
proto.model.OrderItemResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderItemResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional string created_by_id = 27;
 * @return {string}
 */
proto.model.OrderItemResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string updated_by_id = 28;
 * @return {string}
 */
proto.model.OrderItemResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderItemResponse} returns this
 */
proto.model.OrderItemResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.OrderResponse.repeatedFields_ = [31,32,37];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.OrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.OrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.OrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dealStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    clientId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    client: (f = msg.getClient()) && proto.model.CompanyResponse.toObject(includeInstance, f),
    exporterId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    exporter: (f = msg.getExporter()) && proto.model.CompanyResponse.toObject(includeInstance, f),
    terminalAddressId: jspb.Message.getFieldWithDefault(msg, 19, ""),
    terminalAddress: (f = msg.getTerminalAddress()) && proto.model.AddressResponse.toObject(includeInstance, f),
    sourceAddressId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    sourceAddress: (f = msg.getSourceAddress()) && proto.model.AddressResponse.toObject(includeInstance, f),
    sourceAddressDetails: (f = msg.getSourceAddressDetails()) && proto.model.OrderAddressDetails.toObject(includeInstance, f),
    destinationAddressId: jspb.Message.getFieldWithDefault(msg, 24, ""),
    destinationAddress: (f = msg.getDestinationAddress()) && proto.model.AddressResponse.toObject(includeInstance, f),
    destinationAddressDetails: (f = msg.getDestinationAddressDetails()) && proto.model.OrderAddressDetails.toObject(includeInstance, f),
    customsAddressId: jspb.Message.getFieldWithDefault(msg, 27, ""),
    customsAddress: (f = msg.getCustomsAddress()) && proto.model.AddressResponse.toObject(includeInstance, f),
    customsAddressDetails: (f = msg.getCustomsAddressDetails()) && proto.model.OrderAddressDetails.toObject(includeInstance, f),
    isCustomsAddressSameAsSource: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    customsAdditionalAddressesIdsList: (f = jspb.Message.getRepeatedField(msg, 31)) == null ? undefined : f,
    customsAdditionalAddressesList: jspb.Message.toObjectList(msg.getCustomsAdditionalAddressesList(),
    proto.model.AddressResponse.toObject, includeInstance),
    secondCustomsAddressId: jspb.Message.getFieldWithDefault(msg, 33, ""),
    secondCustomsAddress: (f = msg.getSecondCustomsAddress()) && proto.model.AddressResponse.toObject(includeInstance, f),
    secondCustomsAddressDetails: (f = msg.getSecondCustomsAddressDetails()) && proto.model.OrderAddressDetails.toObject(includeInstance, f),
    dealType: jspb.Message.getFieldWithDefault(msg, 36, 0),
    cargoItemsList: jspb.Message.toObjectList(msg.getCargoItemsList(),
    proto.model.OrderItemResponse.toObject, includeInstance),
    weightUnit: jspb.Message.getFieldWithDefault(msg, 38, 0),
    weight: jspb.Message.getFieldWithDefault(msg, 39, ""),
    clientRequestedPrice: jspb.Message.getFieldWithDefault(msg, 40, ""),
    clientRequestedPriceCurrencyId: jspb.Message.getFieldWithDefault(msg, 41, ""),
    clientRequestedPriceCurrency: (f = msg.getClientRequestedPriceCurrency()) && proto.model.CurrencyResponse.toObject(includeInstance, f),
    clientResponsePrice: jspb.Message.getFieldWithDefault(msg, 43, ""),
    transportationPrice: jspb.Message.getFieldWithDefault(msg, 45, ""),
    transportationCurrencyId: jspb.Message.getFieldWithDefault(msg, 46, ""),
    transportationCurrency: (f = msg.getTransportationCurrency()) && proto.model.CurrencyResponse.toObject(includeInstance, f),
    brokerageServices: jspb.Message.getFieldWithDefault(msg, 50, 0),
    brokerageServicesCompanyId: jspb.Message.getFieldWithDefault(msg, 51, ""),
    brokerageServicesCompany: (f = msg.getBrokerageServicesCompany()) && proto.model.CompanyResponse.toObject(includeInstance, f),
    brokerageServicesCompanyDetails: (f = msg.getBrokerageServicesCompanyDetails()) && proto.model.BrokerageServicesCompanyDetails.toObject(includeInstance, f),
    exportServices: jspb.Message.getBooleanFieldWithDefault(msg, 54, false),
    transitServices: jspb.Message.getBooleanFieldWithDefault(msg, 55, false),
    loadDate: (f = msg.getLoadDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 57, ""),
    newOrderMessageInfo: (f = msg.getNewOrderMessageInfo()) && proto.model.NewOrderMessageResponse.toObject(includeInstance, f),
    dispatchLoadingStatus: jspb.Message.getFieldWithDefault(msg, 59, 0),
    dispatchDeliveryStatus: jspb.Message.getFieldWithDefault(msg, 60, 0),
    orderTransporterDirection: (f = msg.getOrderTransporterDirection()) && proto.model.OrderTransporterDirectionResponse.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 72, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 73, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.OrderResponse}
 */
proto.model.OrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.OrderResponse;
  return proto.model.OrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.OrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.OrderResponse}
 */
proto.model.OrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 6:
      var value = /** @type {!proto.model.DealStatus} */ (reader.readEnum());
      msg.setDealStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 10:
      var value = new proto.model.CompanyResponse;
      reader.readMessage(value,proto.model.CompanyResponse.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setExporterId(value);
      break;
    case 12:
      var value = new proto.model.CompanyResponse;
      reader.readMessage(value,proto.model.CompanyResponse.deserializeBinaryFromReader);
      msg.setExporter(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerminalAddressId(value);
      break;
    case 20:
      var value = new proto.model.AddressResponse;
      reader.readMessage(value,proto.model.AddressResponse.deserializeBinaryFromReader);
      msg.setTerminalAddress(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceAddressId(value);
      break;
    case 22:
      var value = new proto.model.AddressResponse;
      reader.readMessage(value,proto.model.AddressResponse.deserializeBinaryFromReader);
      msg.setSourceAddress(value);
      break;
    case 23:
      var value = new proto.model.OrderAddressDetails;
      reader.readMessage(value,proto.model.OrderAddressDetails.deserializeBinaryFromReader);
      msg.setSourceAddressDetails(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationAddressId(value);
      break;
    case 25:
      var value = new proto.model.AddressResponse;
      reader.readMessage(value,proto.model.AddressResponse.deserializeBinaryFromReader);
      msg.setDestinationAddress(value);
      break;
    case 26:
      var value = new proto.model.OrderAddressDetails;
      reader.readMessage(value,proto.model.OrderAddressDetails.deserializeBinaryFromReader);
      msg.setDestinationAddressDetails(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomsAddressId(value);
      break;
    case 28:
      var value = new proto.model.AddressResponse;
      reader.readMessage(value,proto.model.AddressResponse.deserializeBinaryFromReader);
      msg.setCustomsAddress(value);
      break;
    case 29:
      var value = new proto.model.OrderAddressDetails;
      reader.readMessage(value,proto.model.OrderAddressDetails.deserializeBinaryFromReader);
      msg.setCustomsAddressDetails(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustomsAddressSameAsSource(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.addCustomsAdditionalAddressesIds(value);
      break;
    case 32:
      var value = new proto.model.AddressResponse;
      reader.readMessage(value,proto.model.AddressResponse.deserializeBinaryFromReader);
      msg.addCustomsAdditionalAddresses(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondCustomsAddressId(value);
      break;
    case 34:
      var value = new proto.model.AddressResponse;
      reader.readMessage(value,proto.model.AddressResponse.deserializeBinaryFromReader);
      msg.setSecondCustomsAddress(value);
      break;
    case 35:
      var value = new proto.model.OrderAddressDetails;
      reader.readMessage(value,proto.model.OrderAddressDetails.deserializeBinaryFromReader);
      msg.setSecondCustomsAddressDetails(value);
      break;
    case 36:
      var value = /** @type {!proto.model.DealType} */ (reader.readEnum());
      msg.setDealType(value);
      break;
    case 37:
      var value = new proto.model.OrderItemResponse;
      reader.readMessage(value,proto.model.OrderItemResponse.deserializeBinaryFromReader);
      msg.addCargoItems(value);
      break;
    case 38:
      var value = /** @type {!proto.model.WeightUnit} */ (reader.readEnum());
      msg.setWeightUnit(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setWeight(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientRequestedPrice(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientRequestedPriceCurrencyId(value);
      break;
    case 42:
      var value = new proto.model.CurrencyResponse;
      reader.readMessage(value,proto.model.CurrencyResponse.deserializeBinaryFromReader);
      msg.setClientRequestedPriceCurrency(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientResponsePrice(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationPrice(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationCurrencyId(value);
      break;
    case 47:
      var value = new proto.model.CurrencyResponse;
      reader.readMessage(value,proto.model.CurrencyResponse.deserializeBinaryFromReader);
      msg.setTransportationCurrency(value);
      break;
    case 50:
      var value = /** @type {!proto.model.OrderBrokerageServices} */ (reader.readEnum());
      msg.setBrokerageServices(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrokerageServicesCompanyId(value);
      break;
    case 52:
      var value = new proto.model.CompanyResponse;
      reader.readMessage(value,proto.model.CompanyResponse.deserializeBinaryFromReader);
      msg.setBrokerageServicesCompany(value);
      break;
    case 53:
      var value = new proto.model.BrokerageServicesCompanyDetails;
      reader.readMessage(value,proto.model.BrokerageServicesCompanyDetails.deserializeBinaryFromReader);
      msg.setBrokerageServicesCompanyDetails(value);
      break;
    case 54:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExportServices(value);
      break;
    case 55:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTransitServices(value);
      break;
    case 56:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLoadDate(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 58:
      var value = new proto.model.NewOrderMessageResponse;
      reader.readMessage(value,proto.model.NewOrderMessageResponse.deserializeBinaryFromReader);
      msg.setNewOrderMessageInfo(value);
      break;
    case 59:
      var value = /** @type {!proto.model.DispatchLoadingStatus} */ (reader.readEnum());
      msg.setDispatchLoadingStatus(value);
      break;
    case 60:
      var value = /** @type {!proto.model.DispatchDeliveryStatus} */ (reader.readEnum());
      msg.setDispatchDeliveryStatus(value);
      break;
    case 62:
      var value = new proto.model.OrderTransporterDirectionResponse;
      reader.readMessage(value,proto.model.OrderTransporterDirectionResponse.deserializeBinaryFromReader);
      msg.setOrderTransporterDirection(value);
      break;
    case 70:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 71:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 72:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 73:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.OrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.OrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.OrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDealStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.model.CompanyResponse.serializeBinaryToWriter
    );
  }
  f = message.getExporterId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getExporter();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.model.CompanyResponse.serializeBinaryToWriter
    );
  }
  f = message.getTerminalAddressId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getTerminalAddress();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.model.AddressResponse.serializeBinaryToWriter
    );
  }
  f = message.getSourceAddressId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getSourceAddress();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.model.AddressResponse.serializeBinaryToWriter
    );
  }
  f = message.getSourceAddressDetails();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.model.OrderAddressDetails.serializeBinaryToWriter
    );
  }
  f = message.getDestinationAddressId();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getDestinationAddress();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.model.AddressResponse.serializeBinaryToWriter
    );
  }
  f = message.getDestinationAddressDetails();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.model.OrderAddressDetails.serializeBinaryToWriter
    );
  }
  f = message.getCustomsAddressId();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getCustomsAddress();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.model.AddressResponse.serializeBinaryToWriter
    );
  }
  f = message.getCustomsAddressDetails();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.model.OrderAddressDetails.serializeBinaryToWriter
    );
  }
  f = message.getIsCustomsAddressSameAsSource();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getCustomsAdditionalAddressesIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      31,
      f
    );
  }
  f = message.getCustomsAdditionalAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      32,
      f,
      proto.model.AddressResponse.serializeBinaryToWriter
    );
  }
  f = message.getSecondCustomsAddressId();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getSecondCustomsAddress();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.model.AddressResponse.serializeBinaryToWriter
    );
  }
  f = message.getSecondCustomsAddressDetails();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.model.OrderAddressDetails.serializeBinaryToWriter
    );
  }
  f = message.getDealType();
  if (f !== 0.0) {
    writer.writeEnum(
      36,
      f
    );
  }
  f = message.getCargoItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      37,
      f,
      proto.model.OrderItemResponse.serializeBinaryToWriter
    );
  }
  f = message.getWeightUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      38,
      f
    );
  }
  f = message.getWeight();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getClientRequestedPrice();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getClientRequestedPriceCurrencyId();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getClientRequestedPriceCurrency();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      proto.model.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getClientResponsePrice();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getTransportationPrice();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getTransportationCurrencyId();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getTransportationCurrency();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      proto.model.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getBrokerageServices();
  if (f !== 0.0) {
    writer.writeEnum(
      50,
      f
    );
  }
  f = message.getBrokerageServicesCompanyId();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getBrokerageServicesCompany();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.model.CompanyResponse.serializeBinaryToWriter
    );
  }
  f = message.getBrokerageServicesCompanyDetails();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      proto.model.BrokerageServicesCompanyDetails.serializeBinaryToWriter
    );
  }
  f = message.getExportServices();
  if (f) {
    writer.writeBool(
      54,
      f
    );
  }
  f = message.getTransitServices();
  if (f) {
    writer.writeBool(
      55,
      f
    );
  }
  f = message.getLoadDate();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      57,
      f
    );
  }
  f = message.getNewOrderMessageInfo();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      proto.model.NewOrderMessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getDispatchLoadingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      59,
      f
    );
  }
  f = message.getDispatchDeliveryStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      60,
      f
    );
  }
  f = message.getOrderTransporterDirection();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      proto.model.OrderTransporterDirectionResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      70,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      71,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      72,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      73,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 5;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional DealStatus deal_status = 6;
 * @return {!proto.model.DealStatus}
 */
proto.model.OrderResponse.prototype.getDealStatus = function() {
  return /** @type {!proto.model.DealStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.model.DealStatus} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setDealStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string client_id = 9;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional CompanyResponse client = 10;
 * @return {?proto.model.CompanyResponse}
 */
proto.model.OrderResponse.prototype.getClient = function() {
  return /** @type{?proto.model.CompanyResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CompanyResponse, 10));
};


/**
 * @param {?proto.model.CompanyResponse|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasClient = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string exporter_id = 11;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getExporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setExporterId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional CompanyResponse exporter = 12;
 * @return {?proto.model.CompanyResponse}
 */
proto.model.OrderResponse.prototype.getExporter = function() {
  return /** @type{?proto.model.CompanyResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CompanyResponse, 12));
};


/**
 * @param {?proto.model.CompanyResponse|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setExporter = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearExporter = function() {
  return this.setExporter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasExporter = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string terminal_address_id = 19;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getTerminalAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setTerminalAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional AddressResponse terminal_address = 20;
 * @return {?proto.model.AddressResponse}
 */
proto.model.OrderResponse.prototype.getTerminalAddress = function() {
  return /** @type{?proto.model.AddressResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.AddressResponse, 20));
};


/**
 * @param {?proto.model.AddressResponse|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setTerminalAddress = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearTerminalAddress = function() {
  return this.setTerminalAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasTerminalAddress = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string source_address_id = 21;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getSourceAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setSourceAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional AddressResponse source_address = 22;
 * @return {?proto.model.AddressResponse}
 */
proto.model.OrderResponse.prototype.getSourceAddress = function() {
  return /** @type{?proto.model.AddressResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.AddressResponse, 22));
};


/**
 * @param {?proto.model.AddressResponse|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setSourceAddress = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearSourceAddress = function() {
  return this.setSourceAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasSourceAddress = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional OrderAddressDetails source_address_details = 23;
 * @return {?proto.model.OrderAddressDetails}
 */
proto.model.OrderResponse.prototype.getSourceAddressDetails = function() {
  return /** @type{?proto.model.OrderAddressDetails} */ (
    jspb.Message.getWrapperField(this, proto.model.OrderAddressDetails, 23));
};


/**
 * @param {?proto.model.OrderAddressDetails|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setSourceAddressDetails = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearSourceAddressDetails = function() {
  return this.setSourceAddressDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasSourceAddressDetails = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional string destination_address_id = 24;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getDestinationAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setDestinationAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional AddressResponse destination_address = 25;
 * @return {?proto.model.AddressResponse}
 */
proto.model.OrderResponse.prototype.getDestinationAddress = function() {
  return /** @type{?proto.model.AddressResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.AddressResponse, 25));
};


/**
 * @param {?proto.model.AddressResponse|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setDestinationAddress = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearDestinationAddress = function() {
  return this.setDestinationAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasDestinationAddress = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional OrderAddressDetails destination_address_details = 26;
 * @return {?proto.model.OrderAddressDetails}
 */
proto.model.OrderResponse.prototype.getDestinationAddressDetails = function() {
  return /** @type{?proto.model.OrderAddressDetails} */ (
    jspb.Message.getWrapperField(this, proto.model.OrderAddressDetails, 26));
};


/**
 * @param {?proto.model.OrderAddressDetails|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setDestinationAddressDetails = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearDestinationAddressDetails = function() {
  return this.setDestinationAddressDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasDestinationAddressDetails = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional string customs_address_id = 27;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getCustomsAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setCustomsAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional AddressResponse customs_address = 28;
 * @return {?proto.model.AddressResponse}
 */
proto.model.OrderResponse.prototype.getCustomsAddress = function() {
  return /** @type{?proto.model.AddressResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.AddressResponse, 28));
};


/**
 * @param {?proto.model.AddressResponse|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setCustomsAddress = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearCustomsAddress = function() {
  return this.setCustomsAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasCustomsAddress = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional OrderAddressDetails customs_address_details = 29;
 * @return {?proto.model.OrderAddressDetails}
 */
proto.model.OrderResponse.prototype.getCustomsAddressDetails = function() {
  return /** @type{?proto.model.OrderAddressDetails} */ (
    jspb.Message.getWrapperField(this, proto.model.OrderAddressDetails, 29));
};


/**
 * @param {?proto.model.OrderAddressDetails|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setCustomsAddressDetails = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearCustomsAddressDetails = function() {
  return this.setCustomsAddressDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasCustomsAddressDetails = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional bool is_customs_address_same_as_source = 30;
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.getIsCustomsAddressSameAsSource = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setIsCustomsAddressSameAsSource = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * repeated string customs_additional_addresses_ids = 31;
 * @return {!Array<string>}
 */
proto.model.OrderResponse.prototype.getCustomsAdditionalAddressesIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 31));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setCustomsAdditionalAddressesIdsList = function(value) {
  return jspb.Message.setField(this, 31, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.addCustomsAdditionalAddressesIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 31, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearCustomsAdditionalAddressesIdsList = function() {
  return this.setCustomsAdditionalAddressesIdsList([]);
};


/**
 * repeated AddressResponse customs_additional_addresses = 32;
 * @return {!Array<!proto.model.AddressResponse>}
 */
proto.model.OrderResponse.prototype.getCustomsAdditionalAddressesList = function() {
  return /** @type{!Array<!proto.model.AddressResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.AddressResponse, 32));
};


/**
 * @param {!Array<!proto.model.AddressResponse>} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setCustomsAdditionalAddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 32, value);
};


/**
 * @param {!proto.model.AddressResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.AddressResponse}
 */
proto.model.OrderResponse.prototype.addCustomsAdditionalAddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 32, opt_value, proto.model.AddressResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearCustomsAdditionalAddressesList = function() {
  return this.setCustomsAdditionalAddressesList([]);
};


/**
 * optional string second_customs_address_id = 33;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getSecondCustomsAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setSecondCustomsAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional AddressResponse second_customs_address = 34;
 * @return {?proto.model.AddressResponse}
 */
proto.model.OrderResponse.prototype.getSecondCustomsAddress = function() {
  return /** @type{?proto.model.AddressResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.AddressResponse, 34));
};


/**
 * @param {?proto.model.AddressResponse|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setSecondCustomsAddress = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearSecondCustomsAddress = function() {
  return this.setSecondCustomsAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasSecondCustomsAddress = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional OrderAddressDetails second_customs_address_details = 35;
 * @return {?proto.model.OrderAddressDetails}
 */
proto.model.OrderResponse.prototype.getSecondCustomsAddressDetails = function() {
  return /** @type{?proto.model.OrderAddressDetails} */ (
    jspb.Message.getWrapperField(this, proto.model.OrderAddressDetails, 35));
};


/**
 * @param {?proto.model.OrderAddressDetails|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setSecondCustomsAddressDetails = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearSecondCustomsAddressDetails = function() {
  return this.setSecondCustomsAddressDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasSecondCustomsAddressDetails = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional DealType deal_type = 36;
 * @return {!proto.model.DealType}
 */
proto.model.OrderResponse.prototype.getDealType = function() {
  return /** @type {!proto.model.DealType} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
};


/**
 * @param {!proto.model.DealType} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setDealType = function(value) {
  return jspb.Message.setProto3EnumField(this, 36, value);
};


/**
 * repeated OrderItemResponse cargo_items = 37;
 * @return {!Array<!proto.model.OrderItemResponse>}
 */
proto.model.OrderResponse.prototype.getCargoItemsList = function() {
  return /** @type{!Array<!proto.model.OrderItemResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.OrderItemResponse, 37));
};


/**
 * @param {!Array<!proto.model.OrderItemResponse>} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setCargoItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 37, value);
};


/**
 * @param {!proto.model.OrderItemResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.OrderItemResponse}
 */
proto.model.OrderResponse.prototype.addCargoItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 37, opt_value, proto.model.OrderItemResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearCargoItemsList = function() {
  return this.setCargoItemsList([]);
};


/**
 * optional WeightUnit weight_unit = 38;
 * @return {!proto.model.WeightUnit}
 */
proto.model.OrderResponse.prototype.getWeightUnit = function() {
  return /** @type {!proto.model.WeightUnit} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/**
 * @param {!proto.model.WeightUnit} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setWeightUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 38, value);
};


/**
 * optional string weight = 39;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getWeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setWeight = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string client_requested_price = 40;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getClientRequestedPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setClientRequestedPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string client_requested_price_currency_id = 41;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getClientRequestedPriceCurrencyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setClientRequestedPriceCurrencyId = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional CurrencyResponse client_requested_price_currency = 42;
 * @return {?proto.model.CurrencyResponse}
 */
proto.model.OrderResponse.prototype.getClientRequestedPriceCurrency = function() {
  return /** @type{?proto.model.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CurrencyResponse, 42));
};


/**
 * @param {?proto.model.CurrencyResponse|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setClientRequestedPriceCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearClientRequestedPriceCurrency = function() {
  return this.setClientRequestedPriceCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasClientRequestedPriceCurrency = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional string client_response_price = 43;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getClientResponsePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setClientResponsePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string transportation_price = 45;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getTransportationPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setTransportationPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string transportation_currency_id = 46;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getTransportationCurrencyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setTransportationCurrencyId = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional CurrencyResponse transportation_currency = 47;
 * @return {?proto.model.CurrencyResponse}
 */
proto.model.OrderResponse.prototype.getTransportationCurrency = function() {
  return /** @type{?proto.model.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CurrencyResponse, 47));
};


/**
 * @param {?proto.model.CurrencyResponse|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setTransportationCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearTransportationCurrency = function() {
  return this.setTransportationCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasTransportationCurrency = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional OrderBrokerageServices brokerage_services = 50;
 * @return {!proto.model.OrderBrokerageServices}
 */
proto.model.OrderResponse.prototype.getBrokerageServices = function() {
  return /** @type {!proto.model.OrderBrokerageServices} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {!proto.model.OrderBrokerageServices} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setBrokerageServices = function(value) {
  return jspb.Message.setProto3EnumField(this, 50, value);
};


/**
 * optional string brokerage_services_company_id = 51;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getBrokerageServicesCompanyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setBrokerageServicesCompanyId = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional CompanyResponse brokerage_services_company = 52;
 * @return {?proto.model.CompanyResponse}
 */
proto.model.OrderResponse.prototype.getBrokerageServicesCompany = function() {
  return /** @type{?proto.model.CompanyResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CompanyResponse, 52));
};


/**
 * @param {?proto.model.CompanyResponse|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setBrokerageServicesCompany = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearBrokerageServicesCompany = function() {
  return this.setBrokerageServicesCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasBrokerageServicesCompany = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional BrokerageServicesCompanyDetails brokerage_services_company_details = 53;
 * @return {?proto.model.BrokerageServicesCompanyDetails}
 */
proto.model.OrderResponse.prototype.getBrokerageServicesCompanyDetails = function() {
  return /** @type{?proto.model.BrokerageServicesCompanyDetails} */ (
    jspb.Message.getWrapperField(this, proto.model.BrokerageServicesCompanyDetails, 53));
};


/**
 * @param {?proto.model.BrokerageServicesCompanyDetails|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setBrokerageServicesCompanyDetails = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearBrokerageServicesCompanyDetails = function() {
  return this.setBrokerageServicesCompanyDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasBrokerageServicesCompanyDetails = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional bool export_services = 54;
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.getExportServices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 54, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setExportServices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 54, value);
};


/**
 * optional bool transit_services = 55;
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.getTransitServices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 55, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setTransitServices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 55, value);
};


/**
 * optional google.protobuf.Timestamp load_date = 56;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderResponse.prototype.getLoadDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 56));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setLoadDate = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearLoadDate = function() {
  return this.setLoadDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasLoadDate = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional string comment = 57;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 57, value);
};


/**
 * optional NewOrderMessageResponse new_order_message_info = 58;
 * @return {?proto.model.NewOrderMessageResponse}
 */
proto.model.OrderResponse.prototype.getNewOrderMessageInfo = function() {
  return /** @type{?proto.model.NewOrderMessageResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.NewOrderMessageResponse, 58));
};


/**
 * @param {?proto.model.NewOrderMessageResponse|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setNewOrderMessageInfo = function(value) {
  return jspb.Message.setWrapperField(this, 58, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearNewOrderMessageInfo = function() {
  return this.setNewOrderMessageInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasNewOrderMessageInfo = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional DispatchLoadingStatus dispatch_loading_status = 59;
 * @return {!proto.model.DispatchLoadingStatus}
 */
proto.model.OrderResponse.prototype.getDispatchLoadingStatus = function() {
  return /** @type {!proto.model.DispatchLoadingStatus} */ (jspb.Message.getFieldWithDefault(this, 59, 0));
};


/**
 * @param {!proto.model.DispatchLoadingStatus} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setDispatchLoadingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 59, value);
};


/**
 * optional DispatchDeliveryStatus dispatch_delivery_status = 60;
 * @return {!proto.model.DispatchDeliveryStatus}
 */
proto.model.OrderResponse.prototype.getDispatchDeliveryStatus = function() {
  return /** @type {!proto.model.DispatchDeliveryStatus} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {!proto.model.DispatchDeliveryStatus} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setDispatchDeliveryStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 60, value);
};


/**
 * optional OrderTransporterDirectionResponse order_transporter_direction = 62;
 * @return {?proto.model.OrderTransporterDirectionResponse}
 */
proto.model.OrderResponse.prototype.getOrderTransporterDirection = function() {
  return /** @type{?proto.model.OrderTransporterDirectionResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.OrderTransporterDirectionResponse, 62));
};


/**
 * @param {?proto.model.OrderTransporterDirectionResponse|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setOrderTransporterDirection = function(value) {
  return jspb.Message.setWrapperField(this, 62, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearOrderTransporterDirection = function() {
  return this.setOrderTransporterDirection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasOrderTransporterDirection = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 70;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 70));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 70, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 70) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 71;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 71));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderResponse} returns this
*/
proto.model.OrderResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 71, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 71) != null;
};


/**
 * optional string created_by_id = 72;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 72, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 72, value);
};


/**
 * optional string updated_by_id = 73;
 * @return {string}
 */
proto.model.OrderResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 73, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderResponse} returns this
 */
proto.model.OrderResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 73, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.CheckedDocTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.CheckedDocTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.CheckedDocTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CheckedDocTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderItemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    docTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isChecked: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.CheckedDocTypeResponse}
 */
proto.model.CheckedDocTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.CheckedDocTypeResponse;
  return proto.model.CheckedDocTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.CheckedDocTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.CheckedDocTypeResponse}
 */
proto.model.CheckedDocTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocTypeId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsChecked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.CheckedDocTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.CheckedDocTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.CheckedDocTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CheckedDocTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocTypeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsChecked();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string order_item_id = 1;
 * @return {string}
 */
proto.model.CheckedDocTypeResponse.prototype.getOrderItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CheckedDocTypeResponse} returns this
 */
proto.model.CheckedDocTypeResponse.prototype.setOrderItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string doc_type_id = 2;
 * @return {string}
 */
proto.model.CheckedDocTypeResponse.prototype.getDocTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CheckedDocTypeResponse} returns this
 */
proto.model.CheckedDocTypeResponse.prototype.setDocTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_checked = 3;
 * @return {boolean}
 */
proto.model.CheckedDocTypeResponse.prototype.getIsChecked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.CheckedDocTypeResponse} returns this
 */
proto.model.CheckedDocTypeResponse.prototype.setIsChecked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.BrokerageServicesCompanyDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.model.BrokerageServicesCompanyDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.BrokerageServicesCompanyDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.BrokerageServicesCompanyDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.BrokerageServicesCompanyDetails}
 */
proto.model.BrokerageServicesCompanyDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.BrokerageServicesCompanyDetails;
  return proto.model.BrokerageServicesCompanyDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.BrokerageServicesCompanyDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.BrokerageServicesCompanyDetails}
 */
proto.model.BrokerageServicesCompanyDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.BrokerageServicesCompanyDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.BrokerageServicesCompanyDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.BrokerageServicesCompanyDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.BrokerageServicesCompanyDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.model.BrokerageServicesCompanyDetails.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.BrokerageServicesCompanyDetails} returns this
 */
proto.model.BrokerageServicesCompanyDetails.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.model.BrokerageServicesCompanyDetails.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.BrokerageServicesCompanyDetails} returns this
 */
proto.model.BrokerageServicesCompanyDetails.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.model.BrokerageServicesCompanyDetails.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.BrokerageServicesCompanyDetails} returns this
 */
proto.model.BrokerageServicesCompanyDetails.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.OrderContractResponse.repeatedFields_ = [28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.OrderContractResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.OrderContractResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.OrderContractResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderContractResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nr: jspb.Message.getFieldWithDefault(msg, 5, 0),
    transportationConditions: jspb.Message.getFieldWithDefault(msg, 6, ""),
    penaltyForCancellation: jspb.Message.getFieldWithDefault(msg, 7, ""),
    timeForLoadingAndCustomsClearance: jspb.Message.getFieldWithDefault(msg, 8, ""),
    timeForUnloadingAndCustomsClearance: jspb.Message.getFieldWithDefault(msg, 9, ""),
    checkingDocumentsByTheClient: jspb.Message.getFieldWithDefault(msg, 10, ""),
    paymentType: jspb.Message.getFieldWithDefault(msg, 11, ""),
    paymentPeriodDays: jspb.Message.getFieldWithDefault(msg, 12, 0),
    paymentTerms: jspb.Message.getFieldWithDefault(msg, 13, ""),
    deliveryPeriod: jspb.Message.getFieldWithDefault(msg, 14, ""),
    specialConditions: jspb.Message.getFieldWithDefault(msg, 16, ""),
    isSignedByClient: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    isSignedByTransporter: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    prePayment: jspb.Message.getFieldWithDefault(msg, 19, ""),
    paymentAfterLoad: jspb.Message.getFieldWithDefault(msg, 20, ""),
    client: (f = msg.getClient()) && proto.model.CompanyResponse.toObject(includeInstance, f),
    sourceAddress: (f = msg.getSourceAddress()) && proto.model.AddressResponse.toObject(includeInstance, f),
    destinationAddress: (f = msg.getDestinationAddress()) && proto.model.AddressResponse.toObject(includeInstance, f),
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto.model.OrderStorageFileResponse.toObject, includeInstance),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.OrderContractResponse}
 */
proto.model.OrderContractResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.OrderContractResponse;
  return proto.model.OrderContractResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.OrderContractResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.OrderContractResponse}
 */
proto.model.OrderContractResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNr(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationConditions(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPenaltyForCancellation(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeForLoadingAndCustomsClearance(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeForUnloadingAndCustomsClearance(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckingDocumentsByTheClient(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentType(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaymentPeriodDays(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentTerms(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryPeriod(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialConditions(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSignedByClient(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSignedByTransporter(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrePayment(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentAfterLoad(value);
      break;
    case 25:
      var value = new proto.model.CompanyResponse;
      reader.readMessage(value,proto.model.CompanyResponse.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 26:
      var value = new proto.model.AddressResponse;
      reader.readMessage(value,proto.model.AddressResponse.deserializeBinaryFromReader);
      msg.setSourceAddress(value);
      break;
    case 27:
      var value = new proto.model.AddressResponse;
      reader.readMessage(value,proto.model.AddressResponse.deserializeBinaryFromReader);
      msg.setDestinationAddress(value);
      break;
    case 28:
      var value = new proto.model.OrderStorageFileResponse;
      reader.readMessage(value,proto.model.OrderStorageFileResponse.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    case 29:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.OrderContractResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.OrderContractResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.OrderContractResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderContractResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNr();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTransportationConditions();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPenaltyForCancellation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTimeForLoadingAndCustomsClearance();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTimeForUnloadingAndCustomsClearance();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCheckingDocumentsByTheClient();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPaymentType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPaymentPeriodDays();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getPaymentTerms();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDeliveryPeriod();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSpecialConditions();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getIsSignedByClient();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getIsSignedByTransporter();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getPrePayment();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPaymentAfterLoad();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.model.CompanyResponse.serializeBinaryToWriter
    );
  }
  f = message.getSourceAddress();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.model.AddressResponse.serializeBinaryToWriter
    );
  }
  f = message.getDestinationAddress();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.model.AddressResponse.serializeBinaryToWriter
    );
  }
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      28,
      f,
      proto.model.OrderStorageFileResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.OrderContractResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 nr = 5;
 * @return {number}
 */
proto.model.OrderContractResponse.prototype.getNr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setNr = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string transportation_conditions = 6;
 * @return {string}
 */
proto.model.OrderContractResponse.prototype.getTransportationConditions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setTransportationConditions = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string penalty_for_cancellation = 7;
 * @return {string}
 */
proto.model.OrderContractResponse.prototype.getPenaltyForCancellation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setPenaltyForCancellation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string time_for_loading_and_customs_clearance = 8;
 * @return {string}
 */
proto.model.OrderContractResponse.prototype.getTimeForLoadingAndCustomsClearance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setTimeForLoadingAndCustomsClearance = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string time_for_unloading_and_customs_clearance = 9;
 * @return {string}
 */
proto.model.OrderContractResponse.prototype.getTimeForUnloadingAndCustomsClearance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setTimeForUnloadingAndCustomsClearance = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string checking_documents_by_the_client = 10;
 * @return {string}
 */
proto.model.OrderContractResponse.prototype.getCheckingDocumentsByTheClient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setCheckingDocumentsByTheClient = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string payment_type = 11;
 * @return {string}
 */
proto.model.OrderContractResponse.prototype.getPaymentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setPaymentType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 payment_period_days = 12;
 * @return {number}
 */
proto.model.OrderContractResponse.prototype.getPaymentPeriodDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setPaymentPeriodDays = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string payment_terms = 13;
 * @return {string}
 */
proto.model.OrderContractResponse.prototype.getPaymentTerms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setPaymentTerms = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string delivery_period = 14;
 * @return {string}
 */
proto.model.OrderContractResponse.prototype.getDeliveryPeriod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setDeliveryPeriod = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string special_conditions = 16;
 * @return {string}
 */
proto.model.OrderContractResponse.prototype.getSpecialConditions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setSpecialConditions = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool is_signed_by_client = 17;
 * @return {boolean}
 */
proto.model.OrderContractResponse.prototype.getIsSignedByClient = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setIsSignedByClient = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool is_signed_by_transporter = 18;
 * @return {boolean}
 */
proto.model.OrderContractResponse.prototype.getIsSignedByTransporter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setIsSignedByTransporter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string pre_payment = 19;
 * @return {string}
 */
proto.model.OrderContractResponse.prototype.getPrePayment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setPrePayment = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string payment_after_load = 20;
 * @return {string}
 */
proto.model.OrderContractResponse.prototype.getPaymentAfterLoad = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.setPaymentAfterLoad = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional CompanyResponse client = 25;
 * @return {?proto.model.CompanyResponse}
 */
proto.model.OrderContractResponse.prototype.getClient = function() {
  return /** @type{?proto.model.CompanyResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CompanyResponse, 25));
};


/**
 * @param {?proto.model.CompanyResponse|undefined} value
 * @return {!proto.model.OrderContractResponse} returns this
*/
proto.model.OrderContractResponse.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderContractResponse.prototype.hasClient = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional AddressResponse source_address = 26;
 * @return {?proto.model.AddressResponse}
 */
proto.model.OrderContractResponse.prototype.getSourceAddress = function() {
  return /** @type{?proto.model.AddressResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.AddressResponse, 26));
};


/**
 * @param {?proto.model.AddressResponse|undefined} value
 * @return {!proto.model.OrderContractResponse} returns this
*/
proto.model.OrderContractResponse.prototype.setSourceAddress = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.clearSourceAddress = function() {
  return this.setSourceAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderContractResponse.prototype.hasSourceAddress = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional AddressResponse destination_address = 27;
 * @return {?proto.model.AddressResponse}
 */
proto.model.OrderContractResponse.prototype.getDestinationAddress = function() {
  return /** @type{?proto.model.AddressResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.AddressResponse, 27));
};


/**
 * @param {?proto.model.AddressResponse|undefined} value
 * @return {!proto.model.OrderContractResponse} returns this
*/
proto.model.OrderContractResponse.prototype.setDestinationAddress = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.clearDestinationAddress = function() {
  return this.setDestinationAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderContractResponse.prototype.hasDestinationAddress = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * repeated OrderStorageFileResponse files = 28;
 * @return {!Array<!proto.model.OrderStorageFileResponse>}
 */
proto.model.OrderContractResponse.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.model.OrderStorageFileResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.OrderStorageFileResponse, 28));
};


/**
 * @param {!Array<!proto.model.OrderStorageFileResponse>} value
 * @return {!proto.model.OrderContractResponse} returns this
*/
proto.model.OrderContractResponse.prototype.setFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 28, value);
};


/**
 * @param {!proto.model.OrderStorageFileResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.OrderStorageFileResponse}
 */
proto.model.OrderContractResponse.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 28, opt_value, proto.model.OrderStorageFileResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.clearFilesList = function() {
  return this.setFilesList([]);
};


/**
 * optional google.protobuf.Timestamp updated_at = 29;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderContractResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 29));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderContractResponse} returns this
*/
proto.model.OrderContractResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderContractResponse} returns this
 */
proto.model.OrderContractResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderContractResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 29) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.NewOrderMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.NewOrderMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.NewOrderMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.NewOrderMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    countNew: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastMessageId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastMessageMessage: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.NewOrderMessageResponse}
 */
proto.model.NewOrderMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.NewOrderMessageResponse;
  return proto.model.NewOrderMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.NewOrderMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.NewOrderMessageResponse}
 */
proto.model.NewOrderMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountNew(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastMessageId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastMessageMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.NewOrderMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.NewOrderMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.NewOrderMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.NewOrderMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountNew();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLastMessageId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastMessageMessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.model.NewOrderMessageResponse.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.NewOrderMessageResponse} returns this
 */
proto.model.NewOrderMessageResponse.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.model.NewOrderMessageResponse.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.NewOrderMessageResponse} returns this
 */
proto.model.NewOrderMessageResponse.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 count_new = 3;
 * @return {number}
 */
proto.model.NewOrderMessageResponse.prototype.getCountNew = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.NewOrderMessageResponse} returns this
 */
proto.model.NewOrderMessageResponse.prototype.setCountNew = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string last_message_id = 4;
 * @return {string}
 */
proto.model.NewOrderMessageResponse.prototype.getLastMessageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.NewOrderMessageResponse} returns this
 */
proto.model.NewOrderMessageResponse.prototype.setLastMessageId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string last_message_message = 5;
 * @return {string}
 */
proto.model.NewOrderMessageResponse.prototype.getLastMessageMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.NewOrderMessageResponse} returns this
 */
proto.model.NewOrderMessageResponse.prototype.setLastMessageMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.OrderReportedProblemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.OrderReportedProblemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.OrderReportedProblemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderReportedProblemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    culprit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 4, ""),
    typeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: (f = msg.getType()) && proto.model.OrderReportedProblemTypeResponse.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 12, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.OrderReportedProblemResponse}
 */
proto.model.OrderReportedProblemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.OrderReportedProblemResponse;
  return proto.model.OrderReportedProblemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.OrderReportedProblemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.OrderReportedProblemResponse}
 */
proto.model.OrderReportedProblemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 3:
      var value = /** @type {!proto.model.OrderReportedProblemCulprit} */ (reader.readEnum());
      msg.setCulprit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeId(value);
      break;
    case 6:
      var value = new proto.model.OrderReportedProblemTypeResponse;
      reader.readMessage(value,proto.model.OrderReportedProblemTypeResponse.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.OrderReportedProblemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.OrderReportedProblemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.OrderReportedProblemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderReportedProblemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCulprit();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTypeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.model.OrderReportedProblemTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.OrderReportedProblemResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderReportedProblemResponse} returns this
 */
proto.model.OrderReportedProblemResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_id = 2;
 * @return {string}
 */
proto.model.OrderReportedProblemResponse.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderReportedProblemResponse} returns this
 */
proto.model.OrderReportedProblemResponse.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OrderReportedProblemCulprit culprit = 3;
 * @return {!proto.model.OrderReportedProblemCulprit}
 */
proto.model.OrderReportedProblemResponse.prototype.getCulprit = function() {
  return /** @type {!proto.model.OrderReportedProblemCulprit} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.model.OrderReportedProblemCulprit} value
 * @return {!proto.model.OrderReportedProblemResponse} returns this
 */
proto.model.OrderReportedProblemResponse.prototype.setCulprit = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string comment = 4;
 * @return {string}
 */
proto.model.OrderReportedProblemResponse.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderReportedProblemResponse} returns this
 */
proto.model.OrderReportedProblemResponse.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type_id = 5;
 * @return {string}
 */
proto.model.OrderReportedProblemResponse.prototype.getTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderReportedProblemResponse} returns this
 */
proto.model.OrderReportedProblemResponse.prototype.setTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional OrderReportedProblemTypeResponse type = 6;
 * @return {?proto.model.OrderReportedProblemTypeResponse}
 */
proto.model.OrderReportedProblemResponse.prototype.getType = function() {
  return /** @type{?proto.model.OrderReportedProblemTypeResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.OrderReportedProblemTypeResponse, 6));
};


/**
 * @param {?proto.model.OrderReportedProblemTypeResponse|undefined} value
 * @return {!proto.model.OrderReportedProblemResponse} returns this
*/
proto.model.OrderReportedProblemResponse.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderReportedProblemResponse} returns this
 */
proto.model.OrderReportedProblemResponse.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderReportedProblemResponse.prototype.hasType = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderReportedProblemResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderReportedProblemResponse} returns this
*/
proto.model.OrderReportedProblemResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderReportedProblemResponse} returns this
 */
proto.model.OrderReportedProblemResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderReportedProblemResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderReportedProblemResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderReportedProblemResponse} returns this
*/
proto.model.OrderReportedProblemResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderReportedProblemResponse} returns this
 */
proto.model.OrderReportedProblemResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderReportedProblemResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string created_by_id = 12;
 * @return {string}
 */
proto.model.OrderReportedProblemResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderReportedProblemResponse} returns this
 */
proto.model.OrderReportedProblemResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string updated_by_id = 13;
 * @return {string}
 */
proto.model.OrderReportedProblemResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderReportedProblemResponse} returns this
 */
proto.model.OrderReportedProblemResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.OrderReportedProblemTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.OrderReportedProblemTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.OrderReportedProblemTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderReportedProblemTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 5, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.OrderReportedProblemTypeResponse}
 */
proto.model.OrderReportedProblemTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.OrderReportedProblemTypeResponse;
  return proto.model.OrderReportedProblemTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.OrderReportedProblemTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.OrderReportedProblemTypeResponse}
 */
proto.model.OrderReportedProblemTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.OrderReportedProblemTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.OrderReportedProblemTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.OrderReportedProblemTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderReportedProblemTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.OrderReportedProblemTypeResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderReportedProblemTypeResponse} returns this
 */
proto.model.OrderReportedProblemTypeResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.model.OrderReportedProblemTypeResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderReportedProblemTypeResponse} returns this
 */
proto.model.OrderReportedProblemTypeResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderReportedProblemTypeResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderReportedProblemTypeResponse} returns this
*/
proto.model.OrderReportedProblemTypeResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderReportedProblemTypeResponse} returns this
 */
proto.model.OrderReportedProblemTypeResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderReportedProblemTypeResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderReportedProblemTypeResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderReportedProblemTypeResponse} returns this
*/
proto.model.OrderReportedProblemTypeResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderReportedProblemTypeResponse} returns this
 */
proto.model.OrderReportedProblemTypeResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderReportedProblemTypeResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string created_by_id = 5;
 * @return {string}
 */
proto.model.OrderReportedProblemTypeResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderReportedProblemTypeResponse} returns this
 */
proto.model.OrderReportedProblemTypeResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string updated_by_id = 6;
 * @return {string}
 */
proto.model.OrderReportedProblemTypeResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderReportedProblemTypeResponse} returns this
 */
proto.model.OrderReportedProblemTypeResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.OrderStorageFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.OrderStorageFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.OrderStorageFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderStorageFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    docTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    docType: (f = msg.getDocType()) && proto.model.DocTypeResponse.toObject(includeInstance, f),
    file: (f = msg.getFile()) && proto.model.StorageFileTemplateResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.OrderStorageFileResponse}
 */
proto.model.OrderStorageFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.OrderStorageFileResponse;
  return proto.model.OrderStorageFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.OrderStorageFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.OrderStorageFileResponse}
 */
proto.model.OrderStorageFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFileType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocTypeId(value);
      break;
    case 3:
      var value = new proto.model.DocTypeResponse;
      reader.readMessage(value,proto.model.DocTypeResponse.deserializeBinaryFromReader);
      msg.setDocType(value);
      break;
    case 4:
      var value = new proto.model.StorageFileTemplateResponse;
      reader.readMessage(value,proto.model.StorageFileTemplateResponse.deserializeBinaryFromReader);
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.OrderStorageFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.OrderStorageFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.OrderStorageFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderStorageFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileType();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDocTypeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.model.DocTypeResponse.serializeBinaryToWriter
    );
  }
  f = message.getFile();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.model.StorageFileTemplateResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 file_type = 1;
 * @return {number}
 */
proto.model.OrderStorageFileResponse.prototype.getFileType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.OrderStorageFileResponse} returns this
 */
proto.model.OrderStorageFileResponse.prototype.setFileType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string doc_type_id = 2;
 * @return {string}
 */
proto.model.OrderStorageFileResponse.prototype.getDocTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderStorageFileResponse} returns this
 */
proto.model.OrderStorageFileResponse.prototype.setDocTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DocTypeResponse doc_type = 3;
 * @return {?proto.model.DocTypeResponse}
 */
proto.model.OrderStorageFileResponse.prototype.getDocType = function() {
  return /** @type{?proto.model.DocTypeResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.DocTypeResponse, 3));
};


/**
 * @param {?proto.model.DocTypeResponse|undefined} value
 * @return {!proto.model.OrderStorageFileResponse} returns this
*/
proto.model.OrderStorageFileResponse.prototype.setDocType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderStorageFileResponse} returns this
 */
proto.model.OrderStorageFileResponse.prototype.clearDocType = function() {
  return this.setDocType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderStorageFileResponse.prototype.hasDocType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional StorageFileTemplateResponse file = 4;
 * @return {?proto.model.StorageFileTemplateResponse}
 */
proto.model.OrderStorageFileResponse.prototype.getFile = function() {
  return /** @type{?proto.model.StorageFileTemplateResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.StorageFileTemplateResponse, 4));
};


/**
 * @param {?proto.model.StorageFileTemplateResponse|undefined} value
 * @return {!proto.model.OrderStorageFileResponse} returns this
*/
proto.model.OrderStorageFileResponse.prototype.setFile = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderStorageFileResponse} returns this
 */
proto.model.OrderStorageFileResponse.prototype.clearFile = function() {
  return this.setFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderStorageFileResponse.prototype.hasFile = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.OrderTransporterDirectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.OrderTransporterDirectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.OrderTransporterDirectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderTransporterDirectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transportationPrice: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transportationCurrencyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    transportationCurrency: (f = msg.getTransportationCurrency()) && proto.model.CurrencyResponse.toObject(includeInstance, f),
    isFavourite: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isHired: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isTruckAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    truckAvailableDate: (f = msg.getTruckAvailableDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    transporterDirection: (f = msg.getTransporterDirection()) && proto.model.TransporterDirectionResponse.toObject(includeInstance, f),
    isBestOffer: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    truckId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    truck: (f = msg.getTruck()) && proto.model.TransporterTruckResponse.toObject(includeInstance, f),
    trailerId: jspb.Message.getFieldWithDefault(msg, 18, ""),
    trailer: (f = msg.getTrailer()) && proto.model.TransporterTrailerResponse.toObject(includeInstance, f),
    driverId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    driver: (f = msg.getDriver()) && proto.model.TransporterDriverResponse.toObject(includeInstance, f),
    driverDetails: (f = msg.getDriverDetails()) && proto.model.DriverDetails.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 33, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 34, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.OrderTransporterDirectionResponse}
 */
proto.model.OrderTransporterDirectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.OrderTransporterDirectionResponse;
  return proto.model.OrderTransporterDirectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.OrderTransporterDirectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.OrderTransporterDirectionResponse}
 */
proto.model.OrderTransporterDirectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationCurrencyId(value);
      break;
    case 5:
      var value = new proto.model.CurrencyResponse;
      reader.readMessage(value,proto.model.CurrencyResponse.deserializeBinaryFromReader);
      msg.setTransportationCurrency(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavourite(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHired(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTruckAvailable(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTruckAvailableDate(value);
      break;
    case 10:
      var value = new proto.model.TransporterDirectionResponse;
      reader.readMessage(value,proto.model.TransporterDirectionResponse.deserializeBinaryFromReader);
      msg.setTransporterDirection(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBestOffer(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruckId(value);
      break;
    case 17:
      var value = new proto.model.TransporterTruckResponse;
      reader.readMessage(value,proto.model.TransporterTruckResponse.deserializeBinaryFromReader);
      msg.setTruck(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerId(value);
      break;
    case 19:
      var value = new proto.model.TransporterTrailerResponse;
      reader.readMessage(value,proto.model.TransporterTrailerResponse.deserializeBinaryFromReader);
      msg.setTrailer(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 21:
      var value = new proto.model.TransporterDriverResponse;
      reader.readMessage(value,proto.model.TransporterDriverResponse.deserializeBinaryFromReader);
      msg.setDriver(value);
      break;
    case 22:
      var value = new proto.model.DriverDetails;
      reader.readMessage(value,proto.model.DriverDetails.deserializeBinaryFromReader);
      msg.setDriverDetails(value);
      break;
    case 31:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 32:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.OrderTransporterDirectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.OrderTransporterDirectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.OrderTransporterDirectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderTransporterDirectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransportationPrice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransportationCurrencyId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTransportationCurrency();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.model.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsFavourite();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsHired();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsTruckAvailable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getTruckAvailableDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTransporterDirection();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.model.TransporterDirectionResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsBestOffer();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getTruckId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTruck();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.model.TransporterTruckResponse.serializeBinaryToWriter
    );
  }
  f = message.getTrailerId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getTrailer();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.model.TransporterTrailerResponse.serializeBinaryToWriter
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDriver();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.model.TransporterDriverResponse.serializeBinaryToWriter
    );
  }
  f = message.getDriverDetails();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.model.DriverDetails.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transporter_direction_id = 2;
 * @return {string}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string transportation_price = 3;
 * @return {string}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getTransportationPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.setTransportationPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transportation_currency_id = 4;
 * @return {string}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getTransportationCurrencyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.setTransportationCurrencyId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional CurrencyResponse transportation_currency = 5;
 * @return {?proto.model.CurrencyResponse}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getTransportationCurrency = function() {
  return /** @type{?proto.model.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CurrencyResponse, 5));
};


/**
 * @param {?proto.model.CurrencyResponse|undefined} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
*/
proto.model.OrderTransporterDirectionResponse.prototype.setTransportationCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.clearTransportationCurrency = function() {
  return this.setTransportationCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionResponse.prototype.hasTransportationCurrency = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool is_favourite = 6;
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getIsFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.setIsFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_hired = 7;
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getIsHired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.setIsHired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_truck_available = 8;
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getIsTruckAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.setIsTruckAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp truck_available_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getTruckAvailableDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
*/
proto.model.OrderTransporterDirectionResponse.prototype.setTruckAvailableDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.clearTruckAvailableDate = function() {
  return this.setTruckAvailableDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionResponse.prototype.hasTruckAvailableDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional TransporterDirectionResponse transporter_direction = 10;
 * @return {?proto.model.TransporterDirectionResponse}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getTransporterDirection = function() {
  return /** @type{?proto.model.TransporterDirectionResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterDirectionResponse, 10));
};


/**
 * @param {?proto.model.TransporterDirectionResponse|undefined} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
*/
proto.model.OrderTransporterDirectionResponse.prototype.setTransporterDirection = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.clearTransporterDirection = function() {
  return this.setTransporterDirection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionResponse.prototype.hasTransporterDirection = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool is_best_offer = 11;
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getIsBestOffer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.setIsBestOffer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string truck_id = 16;
 * @return {string}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getTruckId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.setTruckId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional TransporterTruckResponse truck = 17;
 * @return {?proto.model.TransporterTruckResponse}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getTruck = function() {
  return /** @type{?proto.model.TransporterTruckResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterTruckResponse, 17));
};


/**
 * @param {?proto.model.TransporterTruckResponse|undefined} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
*/
proto.model.OrderTransporterDirectionResponse.prototype.setTruck = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.clearTruck = function() {
  return this.setTruck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionResponse.prototype.hasTruck = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string trailer_id = 18;
 * @return {string}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getTrailerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.setTrailerId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional TransporterTrailerResponse trailer = 19;
 * @return {?proto.model.TransporterTrailerResponse}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getTrailer = function() {
  return /** @type{?proto.model.TransporterTrailerResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterTrailerResponse, 19));
};


/**
 * @param {?proto.model.TransporterTrailerResponse|undefined} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
*/
proto.model.OrderTransporterDirectionResponse.prototype.setTrailer = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.clearTrailer = function() {
  return this.setTrailer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionResponse.prototype.hasTrailer = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string driver_id = 20;
 * @return {string}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional TransporterDriverResponse driver = 21;
 * @return {?proto.model.TransporterDriverResponse}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getDriver = function() {
  return /** @type{?proto.model.TransporterDriverResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterDriverResponse, 21));
};


/**
 * @param {?proto.model.TransporterDriverResponse|undefined} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
*/
proto.model.OrderTransporterDirectionResponse.prototype.setDriver = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.clearDriver = function() {
  return this.setDriver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionResponse.prototype.hasDriver = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional DriverDetails driver_details = 22;
 * @return {?proto.model.DriverDetails}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getDriverDetails = function() {
  return /** @type{?proto.model.DriverDetails} */ (
    jspb.Message.getWrapperField(this, proto.model.DriverDetails, 22));
};


/**
 * @param {?proto.model.DriverDetails|undefined} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
*/
proto.model.OrderTransporterDirectionResponse.prototype.setDriverDetails = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.clearDriverDetails = function() {
  return this.setDriverDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionResponse.prototype.hasDriverDetails = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 31;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 31));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
*/
proto.model.OrderTransporterDirectionResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 32;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 32));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
*/
proto.model.OrderTransporterDirectionResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional string created_by_id = 33;
 * @return {string}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string updated_by_id = 34;
 * @return {string}
 */
proto.model.OrderTransporterDirectionResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionResponse} returns this
 */
proto.model.OrderTransporterDirectionResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DealOrdersTransporterDirectionInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DealOrdersTransporterDirectionInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isFavourite: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isHired: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isTruckAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    truckAvailableDate: (f = msg.getTruckAvailableDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    transporterDirection: (f = msg.getTransporterDirection()) && proto.model.TransporterDirectionResponse.toObject(includeInstance, f),
    truckId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    truck: (f = msg.getTruck()) && proto.model.TransporterTruckResponse.toObject(includeInstance, f),
    trailerId: jspb.Message.getFieldWithDefault(msg, 18, ""),
    trailer: (f = msg.getTrailer()) && proto.model.TransporterTrailerResponse.toObject(includeInstance, f),
    driverId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    driver: (f = msg.getDriver()) && proto.model.TransporterDriverResponse.toObject(includeInstance, f),
    driverDetails: (f = msg.getDriverDetails()) && proto.model.DriverDetails.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 33, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 34, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DealOrdersTransporterDirectionInfoResponse;
  return proto.model.DealOrdersTransporterDirectionInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DealOrdersTransporterDirectionInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavourite(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHired(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTruckAvailable(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTruckAvailableDate(value);
      break;
    case 10:
      var value = new proto.model.TransporterDirectionResponse;
      reader.readMessage(value,proto.model.TransporterDirectionResponse.deserializeBinaryFromReader);
      msg.setTransporterDirection(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruckId(value);
      break;
    case 17:
      var value = new proto.model.TransporterTruckResponse;
      reader.readMessage(value,proto.model.TransporterTruckResponse.deserializeBinaryFromReader);
      msg.setTruck(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerId(value);
      break;
    case 19:
      var value = new proto.model.TransporterTrailerResponse;
      reader.readMessage(value,proto.model.TransporterTrailerResponse.deserializeBinaryFromReader);
      msg.setTrailer(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 21:
      var value = new proto.model.TransporterDriverResponse;
      reader.readMessage(value,proto.model.TransporterDriverResponse.deserializeBinaryFromReader);
      msg.setDriver(value);
      break;
    case 22:
      var value = new proto.model.DriverDetails;
      reader.readMessage(value,proto.model.DriverDetails.deserializeBinaryFromReader);
      msg.setDriverDetails(value);
      break;
    case 31:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 32:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DealOrdersTransporterDirectionInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DealOrdersTransporterDirectionInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsFavourite();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsHired();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsTruckAvailable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getTruckAvailableDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTransporterDirection();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.model.TransporterDirectionResponse.serializeBinaryToWriter
    );
  }
  f = message.getTruckId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTruck();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.model.TransporterTruckResponse.serializeBinaryToWriter
    );
  }
  f = message.getTrailerId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getTrailer();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.model.TransporterTrailerResponse.serializeBinaryToWriter
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDriver();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.model.TransporterDriverResponse.serializeBinaryToWriter
    );
  }
  f = message.getDriverDetails();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.model.DriverDetails.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
};


/**
 * optional string transporter_direction_id = 2;
 * @return {string}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_favourite = 6;
 * @return {boolean}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getIsFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setIsFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_hired = 7;
 * @return {boolean}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getIsHired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setIsHired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_truck_available = 8;
 * @return {boolean}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getIsTruckAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setIsTruckAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp truck_available_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getTruckAvailableDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
*/
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setTruckAvailableDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.clearTruckAvailableDate = function() {
  return this.setTruckAvailableDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.hasTruckAvailableDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional TransporterDirectionResponse transporter_direction = 10;
 * @return {?proto.model.TransporterDirectionResponse}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getTransporterDirection = function() {
  return /** @type{?proto.model.TransporterDirectionResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterDirectionResponse, 10));
};


/**
 * @param {?proto.model.TransporterDirectionResponse|undefined} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
*/
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setTransporterDirection = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.clearTransporterDirection = function() {
  return this.setTransporterDirection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.hasTransporterDirection = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string truck_id = 16;
 * @return {string}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getTruckId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setTruckId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional TransporterTruckResponse truck = 17;
 * @return {?proto.model.TransporterTruckResponse}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getTruck = function() {
  return /** @type{?proto.model.TransporterTruckResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterTruckResponse, 17));
};


/**
 * @param {?proto.model.TransporterTruckResponse|undefined} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
*/
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setTruck = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.clearTruck = function() {
  return this.setTruck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.hasTruck = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string trailer_id = 18;
 * @return {string}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getTrailerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setTrailerId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional TransporterTrailerResponse trailer = 19;
 * @return {?proto.model.TransporterTrailerResponse}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getTrailer = function() {
  return /** @type{?proto.model.TransporterTrailerResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterTrailerResponse, 19));
};


/**
 * @param {?proto.model.TransporterTrailerResponse|undefined} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
*/
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setTrailer = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.clearTrailer = function() {
  return this.setTrailer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.hasTrailer = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string driver_id = 20;
 * @return {string}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional TransporterDriverResponse driver = 21;
 * @return {?proto.model.TransporterDriverResponse}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getDriver = function() {
  return /** @type{?proto.model.TransporterDriverResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterDriverResponse, 21));
};


/**
 * @param {?proto.model.TransporterDriverResponse|undefined} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
*/
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setDriver = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.clearDriver = function() {
  return this.setDriver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.hasDriver = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional DriverDetails driver_details = 22;
 * @return {?proto.model.DriverDetails}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getDriverDetails = function() {
  return /** @type{?proto.model.DriverDetails} */ (
    jspb.Message.getWrapperField(this, proto.model.DriverDetails, 22));
};


/**
 * @param {?proto.model.DriverDetails|undefined} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
*/
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setDriverDetails = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.clearDriverDetails = function() {
  return this.setDriverDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.hasDriverDetails = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 31;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 31));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
*/
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 32;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 32));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
*/
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional string created_by_id = 33;
 * @return {string}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string updated_by_id = 34;
 * @return {string}
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DealOrdersTransporterDirectionInfoResponse} returns this
 */
proto.model.DealOrdersTransporterDirectionInfoResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.OrderTransporterDirectionPriceInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.OrderTransporterDirectionPriceInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transportationPrice: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transportationPriceCurrencyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    transportationPriceCurrency: (f = msg.getTransportationPriceCurrency()) && proto.model.CurrencyResponse.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 33, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 34, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.OrderTransporterDirectionPriceInfoResponse}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.OrderTransporterDirectionPriceInfoResponse;
  return proto.model.OrderTransporterDirectionPriceInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.OrderTransporterDirectionPriceInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.OrderTransporterDirectionPriceInfoResponse}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationPriceCurrencyId(value);
      break;
    case 5:
      var value = new proto.model.CurrencyResponse;
      reader.readMessage(value,proto.model.CurrencyResponse.deserializeBinaryFromReader);
      msg.setTransportationPriceCurrency(value);
      break;
    case 31:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 32:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.OrderTransporterDirectionPriceInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.OrderTransporterDirectionPriceInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransportationPrice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransportationPriceCurrencyId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTransportationPriceCurrency();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.model.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionPriceInfoResponse} returns this
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transporter_direction_id = 2;
 * @return {string}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionPriceInfoResponse} returns this
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string transportation_price = 3;
 * @return {string}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.getTransportationPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionPriceInfoResponse} returns this
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.setTransportationPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transportation_price_currency_id = 4;
 * @return {string}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.getTransportationPriceCurrencyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionPriceInfoResponse} returns this
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.setTransportationPriceCurrencyId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional CurrencyResponse transportation_price_currency = 5;
 * @return {?proto.model.CurrencyResponse}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.getTransportationPriceCurrency = function() {
  return /** @type{?proto.model.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CurrencyResponse, 5));
};


/**
 * @param {?proto.model.CurrencyResponse|undefined} value
 * @return {!proto.model.OrderTransporterDirectionPriceInfoResponse} returns this
*/
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.setTransportationPriceCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderTransporterDirectionPriceInfoResponse} returns this
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.clearTransportationPriceCurrency = function() {
  return this.setTransportationPriceCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.hasTransportationPriceCurrency = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 31;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 31));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderTransporterDirectionPriceInfoResponse} returns this
*/
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderTransporterDirectionPriceInfoResponse} returns this
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 32;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 32));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.OrderTransporterDirectionPriceInfoResponse} returns this
*/
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.OrderTransporterDirectionPriceInfoResponse} returns this
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional string created_by_id = 33;
 * @return {string}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionPriceInfoResponse} returns this
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string updated_by_id = 34;
 * @return {string}
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.OrderTransporterDirectionPriceInfoResponse} returns this
 */
proto.model.OrderTransporterDirectionPriceInfoResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DriverDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DriverDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DriverDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DriverDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    fullName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    passportNr: jspb.Message.getFieldWithDefault(msg, 3, ""),
    passportValidTill: (f = msg.getPassportValidTill()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DriverDetails}
 */
proto.model.DriverDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DriverDetails;
  return proto.model.DriverDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DriverDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DriverDetails}
 */
proto.model.DriverDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassportNr(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPassportValidTill(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DriverDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DriverDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DriverDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DriverDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassportNr();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassportValidTill();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string full_name = 1;
 * @return {string}
 */
proto.model.DriverDetails.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DriverDetails} returns this
 */
proto.model.DriverDetails.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.model.DriverDetails.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DriverDetails} returns this
 */
proto.model.DriverDetails.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string passport_nr = 3;
 * @return {string}
 */
proto.model.DriverDetails.prototype.getPassportNr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DriverDetails} returns this
 */
proto.model.DriverDetails.prototype.setPassportNr = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp passport_valid_till = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.DriverDetails.prototype.getPassportValidTill = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.DriverDetails} returns this
*/
proto.model.DriverDetails.prototype.setPassportValidTill = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DriverDetails} returns this
 */
proto.model.DriverDetails.prototype.clearPassportValidTill = function() {
  return this.setPassportValidTill(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DriverDetails.prototype.hasPassportValidTill = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.model.JoinedOrderTransporterDirectionGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.JoinedOrderTransporterDirectionGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.JoinedOrderTransporterDirectionGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transporterDirection: (f = msg.getTransporterDirection()) && proto.model.TransporterDirectionResponse.toObject(includeInstance, f),
    isFavourite: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isHired: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isTruckAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    truckAvailableDate: (f = msg.getTruckAvailableDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isDirectionEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    nrOfOrders: jspb.Message.getFieldWithDefault(msg, 15, 0),
    avgRating: jspb.Message.getFieldWithDefault(msg, 16, ""),
    avgPrice: jspb.Message.getFieldWithDefault(msg, 17, ""),
    currentPrice: jspb.Message.getFieldWithDefault(msg, 18, ""),
    prevPrice: jspb.Message.getFieldWithDefault(msg, 19, ""),
    hasPriceIncreased: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    transportationPrice: jspb.Message.getFieldWithDefault(msg, 25, ""),
    transportationCurrencyId: jspb.Message.getFieldWithDefault(msg, 26, ""),
    transportationCurrency: (f = msg.getTransportationCurrency()) && proto.model.CurrencyResponse.toObject(includeInstance, f),
    truckId: jspb.Message.getFieldWithDefault(msg, 30, ""),
    truck: (f = msg.getTruck()) && proto.model.TransporterTruckResponse.toObject(includeInstance, f),
    trailerId: jspb.Message.getFieldWithDefault(msg, 35, ""),
    trailer: (f = msg.getTrailer()) && proto.model.TransporterTrailerResponse.toObject(includeInstance, f),
    driverId: jspb.Message.getFieldWithDefault(msg, 41, ""),
    driver: (f = msg.getDriver()) && proto.model.TransporterDriverResponse.toObject(includeInstance, f),
    driverDetails: (f = msg.getDriverDetails()) && proto.model.DriverDetails.toObject(includeInstance, f),
    sourceCountryId: jspb.Message.getFieldWithDefault(msg, 45, ""),
    sourceCountry: (f = msg.getSourceCountry()) && proto.model.CountryResponse.toObject(includeInstance, f),
    destinationCountryId: jspb.Message.getFieldWithDefault(msg, 50, ""),
    destinationCountry: (f = msg.getDestinationCountry()) && proto.model.CountryResponse.toObject(includeInstance, f),
    transporterId: jspb.Message.getFieldWithDefault(msg, 55, ""),
    transporter: (f = msg.getTransporter()) && proto.model.TransporterResponse.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 60, ""),
    createdBy: (f = msg.getCreatedBy()) && proto.model.UserMetaResponse.toObject(includeInstance, f),
    updatedById: jspb.Message.getFieldWithDefault(msg, 65, ""),
    updatedBy: (f = msg.getUpdatedBy()) && proto.model.UserMetaResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup}
 */
proto.model.JoinedOrderTransporterDirectionGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.JoinedOrderTransporterDirectionGroup;
  return proto.model.JoinedOrderTransporterDirectionGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.JoinedOrderTransporterDirectionGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup}
 */
proto.model.JoinedOrderTransporterDirectionGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 3:
      var value = new proto.model.TransporterDirectionResponse;
      reader.readMessage(value,proto.model.TransporterDirectionResponse.deserializeBinaryFromReader);
      msg.setTransporterDirection(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavourite(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHired(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTruckAvailable(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTruckAvailableDate(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDirectionEnabled(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNrOfOrders(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvgRating(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvgPrice(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentPrice(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrevPrice(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPriceIncreased(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationPrice(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationCurrencyId(value);
      break;
    case 27:
      var value = new proto.model.CurrencyResponse;
      reader.readMessage(value,proto.model.CurrencyResponse.deserializeBinaryFromReader);
      msg.setTransportationCurrency(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruckId(value);
      break;
    case 31:
      var value = new proto.model.TransporterTruckResponse;
      reader.readMessage(value,proto.model.TransporterTruckResponse.deserializeBinaryFromReader);
      msg.setTruck(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerId(value);
      break;
    case 36:
      var value = new proto.model.TransporterTrailerResponse;
      reader.readMessage(value,proto.model.TransporterTrailerResponse.deserializeBinaryFromReader);
      msg.setTrailer(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 42:
      var value = new proto.model.TransporterDriverResponse;
      reader.readMessage(value,proto.model.TransporterDriverResponse.deserializeBinaryFromReader);
      msg.setDriver(value);
      break;
    case 43:
      var value = new proto.model.DriverDetails;
      reader.readMessage(value,proto.model.DriverDetails.deserializeBinaryFromReader);
      msg.setDriverDetails(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceCountryId(value);
      break;
    case 46:
      var value = new proto.model.CountryResponse;
      reader.readMessage(value,proto.model.CountryResponse.deserializeBinaryFromReader);
      msg.setSourceCountry(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationCountryId(value);
      break;
    case 51:
      var value = new proto.model.CountryResponse;
      reader.readMessage(value,proto.model.CountryResponse.deserializeBinaryFromReader);
      msg.setDestinationCountry(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterId(value);
      break;
    case 56:
      var value = new proto.model.TransporterResponse;
      reader.readMessage(value,proto.model.TransporterResponse.deserializeBinaryFromReader);
      msg.setTransporter(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 61:
      var value = new proto.model.UserMetaResponse;
      reader.readMessage(value,proto.model.UserMetaResponse.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 65:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    case 66:
      var value = new proto.model.UserMetaResponse;
      reader.readMessage(value,proto.model.UserMetaResponse.deserializeBinaryFromReader);
      msg.setUpdatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.JoinedOrderTransporterDirectionGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.JoinedOrderTransporterDirectionGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.JoinedOrderTransporterDirectionGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransporterDirection();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.model.TransporterDirectionResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsFavourite();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsHired();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsTruckAvailable();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTruckAvailableDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsDirectionEnabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getNrOfOrders();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getAvgRating();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAvgPrice();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCurrentPrice();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPrevPrice();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getHasPriceIncreased();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getTransportationPrice();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getTransportationCurrencyId();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getTransportationCurrency();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.model.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getTruckId();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getTruck();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.model.TransporterTruckResponse.serializeBinaryToWriter
    );
  }
  f = message.getTrailerId();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getTrailer();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.model.TransporterTrailerResponse.serializeBinaryToWriter
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getDriver();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      proto.model.TransporterDriverResponse.serializeBinaryToWriter
    );
  }
  f = message.getDriverDetails();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      proto.model.DriverDetails.serializeBinaryToWriter
    );
  }
  f = message.getSourceCountryId();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getSourceCountry();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      proto.model.CountryResponse.serializeBinaryToWriter
    );
  }
  f = message.getDestinationCountryId();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getDestinationCountry();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.model.CountryResponse.serializeBinaryToWriter
    );
  }
  f = message.getTransporterId();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getTransporter();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      proto.model.TransporterResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      proto.model.UserMetaResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      65,
      f
    );
  }
  f = message.getUpdatedBy();
  if (f != null) {
    writer.writeMessage(
      66,
      f,
      proto.model.UserMetaResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transporter_direction_id = 2;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TransporterDirectionResponse transporter_direction = 3;
 * @return {?proto.model.TransporterDirectionResponse}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getTransporterDirection = function() {
  return /** @type{?proto.model.TransporterDirectionResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterDirectionResponse, 3));
};


/**
 * @param {?proto.model.TransporterDirectionResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
*/
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setTransporterDirection = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.clearTransporterDirection = function() {
  return this.setTransporterDirection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.hasTransporterDirection = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_favourite = 5;
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getIsFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setIsFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_hired = 6;
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getIsHired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setIsHired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_truck_available = 7;
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getIsTruckAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setIsTruckAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp truck_available_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getTruckAvailableDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
*/
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setTruckAvailableDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.clearTruckAvailableDate = function() {
  return this.setTruckAvailableDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.hasTruckAvailableDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_direction_enabled = 9;
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getIsDirectionEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setIsDirectionEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int64 nr_of_orders = 15;
 * @return {number}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getNrOfOrders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setNrOfOrders = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string avg_rating = 16;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getAvgRating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setAvgRating = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string avg_price = 17;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getAvgPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setAvgPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string current_price = 18;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getCurrentPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setCurrentPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string prev_price = 19;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getPrevPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setPrevPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool has_price_increased = 20;
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getHasPriceIncreased = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setHasPriceIncreased = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional string transportation_price = 25;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getTransportationPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setTransportationPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string transportation_currency_id = 26;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getTransportationCurrencyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setTransportationCurrencyId = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional CurrencyResponse transportation_currency = 27;
 * @return {?proto.model.CurrencyResponse}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getTransportationCurrency = function() {
  return /** @type{?proto.model.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CurrencyResponse, 27));
};


/**
 * @param {?proto.model.CurrencyResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
*/
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setTransportationCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.clearTransportationCurrency = function() {
  return this.setTransportationCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.hasTransportationCurrency = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional string truck_id = 30;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getTruckId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setTruckId = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional TransporterTruckResponse truck = 31;
 * @return {?proto.model.TransporterTruckResponse}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getTruck = function() {
  return /** @type{?proto.model.TransporterTruckResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterTruckResponse, 31));
};


/**
 * @param {?proto.model.TransporterTruckResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
*/
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setTruck = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.clearTruck = function() {
  return this.setTruck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.hasTruck = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional string trailer_id = 35;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getTrailerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setTrailerId = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional TransporterTrailerResponse trailer = 36;
 * @return {?proto.model.TransporterTrailerResponse}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getTrailer = function() {
  return /** @type{?proto.model.TransporterTrailerResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterTrailerResponse, 36));
};


/**
 * @param {?proto.model.TransporterTrailerResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
*/
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setTrailer = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.clearTrailer = function() {
  return this.setTrailer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.hasTrailer = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional string driver_id = 41;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional TransporterDriverResponse driver = 42;
 * @return {?proto.model.TransporterDriverResponse}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getDriver = function() {
  return /** @type{?proto.model.TransporterDriverResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterDriverResponse, 42));
};


/**
 * @param {?proto.model.TransporterDriverResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
*/
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setDriver = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.clearDriver = function() {
  return this.setDriver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.hasDriver = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional DriverDetails driver_details = 43;
 * @return {?proto.model.DriverDetails}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getDriverDetails = function() {
  return /** @type{?proto.model.DriverDetails} */ (
    jspb.Message.getWrapperField(this, proto.model.DriverDetails, 43));
};


/**
 * @param {?proto.model.DriverDetails|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
*/
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setDriverDetails = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.clearDriverDetails = function() {
  return this.setDriverDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.hasDriverDetails = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional string source_country_id = 45;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getSourceCountryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setSourceCountryId = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional CountryResponse source_country = 46;
 * @return {?proto.model.CountryResponse}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getSourceCountry = function() {
  return /** @type{?proto.model.CountryResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CountryResponse, 46));
};


/**
 * @param {?proto.model.CountryResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
*/
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setSourceCountry = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.clearSourceCountry = function() {
  return this.setSourceCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.hasSourceCountry = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional string destination_country_id = 50;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getDestinationCountryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setDestinationCountryId = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional CountryResponse destination_country = 51;
 * @return {?proto.model.CountryResponse}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getDestinationCountry = function() {
  return /** @type{?proto.model.CountryResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CountryResponse, 51));
};


/**
 * @param {?proto.model.CountryResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
*/
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setDestinationCountry = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.clearDestinationCountry = function() {
  return this.setDestinationCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.hasDestinationCountry = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional string transporter_id = 55;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getTransporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setTransporterId = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional TransporterResponse transporter = 56;
 * @return {?proto.model.TransporterResponse}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getTransporter = function() {
  return /** @type{?proto.model.TransporterResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterResponse, 56));
};


/**
 * @param {?proto.model.TransporterResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
*/
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setTransporter = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.clearTransporter = function() {
  return this.setTransporter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.hasTransporter = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional string created_by_id = 60;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional UserMetaResponse created_by = 61;
 * @return {?proto.model.UserMetaResponse}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getCreatedBy = function() {
  return /** @type{?proto.model.UserMetaResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.UserMetaResponse, 61));
};


/**
 * @param {?proto.model.UserMetaResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
*/
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional string updated_by_id = 65;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 65, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 65, value);
};


/**
 * optional UserMetaResponse updated_by = 66;
 * @return {?proto.model.UserMetaResponse}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.getUpdatedBy = function() {
  return /** @type{?proto.model.UserMetaResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.UserMetaResponse, 66));
};


/**
 * @param {?proto.model.UserMetaResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
*/
proto.model.JoinedOrderTransporterDirectionGroup.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 66, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionGroup} returns this
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.clearUpdatedBy = function() {
  return this.setUpdatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionGroup.prototype.hasUpdatedBy = function() {
  return jspb.Message.getField(this, 66) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterDirection: (f = msg.getTransporterDirection()) && proto.model.TransporterDirectionResponse.toObject(includeInstance, f),
    isFavourite: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isHired: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isTruckAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    truckAvailableDate: (f = msg.getTruckAvailableDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isDirectionEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isBestOffer: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    nrOfOrders: jspb.Message.getFieldWithDefault(msg, 15, 0),
    avgRating: jspb.Message.getFieldWithDefault(msg, 16, ""),
    avgPrice: jspb.Message.getFieldWithDefault(msg, 17, ""),
    currentPrice: jspb.Message.getFieldWithDefault(msg, 18, ""),
    prevPrice: jspb.Message.getFieldWithDefault(msg, 19, ""),
    hasPriceIncreased: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    truckId: jspb.Message.getFieldWithDefault(msg, 30, ""),
    truck: (f = msg.getTruck()) && proto.model.TransporterTruckResponse.toObject(includeInstance, f),
    trailerId: jspb.Message.getFieldWithDefault(msg, 35, ""),
    trailer: (f = msg.getTrailer()) && proto.model.TransporterTrailerResponse.toObject(includeInstance, f),
    driverId: jspb.Message.getFieldWithDefault(msg, 41, ""),
    driver: (f = msg.getDriver()) && proto.model.TransporterDriverResponse.toObject(includeInstance, f),
    driverDetails: (f = msg.getDriverDetails()) && proto.model.DriverDetails.toObject(includeInstance, f),
    transporterId: jspb.Message.getFieldWithDefault(msg, 55, ""),
    transporter: (f = msg.getTransporter()) && proto.model.TransporterResponse.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 60, ""),
    createdBy: (f = msg.getCreatedBy()) && proto.model.UserMetaResponse.toObject(includeInstance, f),
    updatedById: jspb.Message.getFieldWithDefault(msg, 65, ""),
    updatedBy: (f = msg.getUpdatedBy()) && proto.model.UserMetaResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse;
  return proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 3:
      var value = new proto.model.TransporterDirectionResponse;
      reader.readMessage(value,proto.model.TransporterDirectionResponse.deserializeBinaryFromReader);
      msg.setTransporterDirection(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavourite(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHired(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTruckAvailable(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTruckAvailableDate(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDirectionEnabled(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBestOffer(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNrOfOrders(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvgRating(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvgPrice(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentPrice(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrevPrice(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPriceIncreased(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruckId(value);
      break;
    case 31:
      var value = new proto.model.TransporterTruckResponse;
      reader.readMessage(value,proto.model.TransporterTruckResponse.deserializeBinaryFromReader);
      msg.setTruck(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerId(value);
      break;
    case 36:
      var value = new proto.model.TransporterTrailerResponse;
      reader.readMessage(value,proto.model.TransporterTrailerResponse.deserializeBinaryFromReader);
      msg.setTrailer(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 42:
      var value = new proto.model.TransporterDriverResponse;
      reader.readMessage(value,proto.model.TransporterDriverResponse.deserializeBinaryFromReader);
      msg.setDriver(value);
      break;
    case 43:
      var value = new proto.model.DriverDetails;
      reader.readMessage(value,proto.model.DriverDetails.deserializeBinaryFromReader);
      msg.setDriverDetails(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterId(value);
      break;
    case 56:
      var value = new proto.model.TransporterResponse;
      reader.readMessage(value,proto.model.TransporterResponse.deserializeBinaryFromReader);
      msg.setTransporter(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 61:
      var value = new proto.model.UserMetaResponse;
      reader.readMessage(value,proto.model.UserMetaResponse.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 65:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    case 66:
      var value = new proto.model.UserMetaResponse;
      reader.readMessage(value,proto.model.UserMetaResponse.deserializeBinaryFromReader);
      msg.setUpdatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterDirection();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.model.TransporterDirectionResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsFavourite();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsHired();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsTruckAvailable();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTruckAvailableDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsDirectionEnabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsBestOffer();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getNrOfOrders();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getAvgRating();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAvgPrice();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCurrentPrice();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getPrevPrice();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getHasPriceIncreased();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getTruckId();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getTruck();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.model.TransporterTruckResponse.serializeBinaryToWriter
    );
  }
  f = message.getTrailerId();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getTrailer();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.model.TransporterTrailerResponse.serializeBinaryToWriter
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getDriver();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      proto.model.TransporterDriverResponse.serializeBinaryToWriter
    );
  }
  f = message.getDriverDetails();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      proto.model.DriverDetails.serializeBinaryToWriter
    );
  }
  f = message.getTransporterId();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getTransporter();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      proto.model.TransporterResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      proto.model.UserMetaResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      65,
      f
    );
  }
  f = message.getUpdatedBy();
  if (f != null) {
    writer.writeMessage(
      66,
      f,
      proto.model.UserMetaResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string transporter_direction_id = 1;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TransporterDirectionResponse transporter_direction = 3;
 * @return {?proto.model.TransporterDirectionResponse}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getTransporterDirection = function() {
  return /** @type{?proto.model.TransporterDirectionResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterDirectionResponse, 3));
};


/**
 * @param {?proto.model.TransporterDirectionResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
*/
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setTransporterDirection = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.clearTransporterDirection = function() {
  return this.setTransporterDirection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.hasTransporterDirection = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_favourite = 5;
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getIsFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setIsFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_hired = 6;
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getIsHired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setIsHired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_truck_available = 7;
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getIsTruckAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setIsTruckAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp truck_available_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getTruckAvailableDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
*/
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setTruckAvailableDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.clearTruckAvailableDate = function() {
  return this.setTruckAvailableDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.hasTruckAvailableDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool is_direction_enabled = 9;
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getIsDirectionEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setIsDirectionEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_best_offer = 10;
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getIsBestOffer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setIsBestOffer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int64 nr_of_orders = 15;
 * @return {number}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getNrOfOrders = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setNrOfOrders = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string avg_rating = 16;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getAvgRating = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setAvgRating = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string avg_price = 17;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getAvgPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setAvgPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string current_price = 18;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getCurrentPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setCurrentPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string prev_price = 19;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getPrevPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setPrevPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool has_price_increased = 20;
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getHasPriceIncreased = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setHasPriceIncreased = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional string truck_id = 30;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getTruckId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setTruckId = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional TransporterTruckResponse truck = 31;
 * @return {?proto.model.TransporterTruckResponse}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getTruck = function() {
  return /** @type{?proto.model.TransporterTruckResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterTruckResponse, 31));
};


/**
 * @param {?proto.model.TransporterTruckResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
*/
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setTruck = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.clearTruck = function() {
  return this.setTruck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.hasTruck = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional string trailer_id = 35;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getTrailerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setTrailerId = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional TransporterTrailerResponse trailer = 36;
 * @return {?proto.model.TransporterTrailerResponse}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getTrailer = function() {
  return /** @type{?proto.model.TransporterTrailerResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterTrailerResponse, 36));
};


/**
 * @param {?proto.model.TransporterTrailerResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
*/
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setTrailer = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.clearTrailer = function() {
  return this.setTrailer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.hasTrailer = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional string driver_id = 41;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional TransporterDriverResponse driver = 42;
 * @return {?proto.model.TransporterDriverResponse}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getDriver = function() {
  return /** @type{?proto.model.TransporterDriverResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterDriverResponse, 42));
};


/**
 * @param {?proto.model.TransporterDriverResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
*/
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setDriver = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.clearDriver = function() {
  return this.setDriver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.hasDriver = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional DriverDetails driver_details = 43;
 * @return {?proto.model.DriverDetails}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getDriverDetails = function() {
  return /** @type{?proto.model.DriverDetails} */ (
    jspb.Message.getWrapperField(this, proto.model.DriverDetails, 43));
};


/**
 * @param {?proto.model.DriverDetails|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
*/
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setDriverDetails = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.clearDriverDetails = function() {
  return this.setDriverDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.hasDriverDetails = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional string transporter_id = 55;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getTransporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setTransporterId = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional TransporterResponse transporter = 56;
 * @return {?proto.model.TransporterResponse}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getTransporter = function() {
  return /** @type{?proto.model.TransporterResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterResponse, 56));
};


/**
 * @param {?proto.model.TransporterResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
*/
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setTransporter = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.clearTransporter = function() {
  return this.setTransporter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.hasTransporter = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional string created_by_id = 60;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional UserMetaResponse created_by = 61;
 * @return {?proto.model.UserMetaResponse}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getCreatedBy = function() {
  return /** @type{?proto.model.UserMetaResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.UserMetaResponse, 61));
};


/**
 * @param {?proto.model.UserMetaResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
*/
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional string updated_by_id = 65;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 65, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 65, value);
};


/**
 * optional UserMetaResponse updated_by = 66;
 * @return {?proto.model.UserMetaResponse}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.getUpdatedBy = function() {
  return /** @type{?proto.model.UserMetaResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.UserMetaResponse, 66));
};


/**
 * @param {?proto.model.UserMetaResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
*/
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 66, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} returns this
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.clearUpdatedBy = function() {
  return this.setUpdatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.prototype.hasUpdatedBy = function() {
  return jspb.Message.getField(this, 66) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.model.JoinedOrderTransporterDirectionOrderInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.JoinedOrderTransporterDirectionOrderInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transportationPrice: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transportationPriceCurrencyId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transportationPriceCurrency: (f = msg.getTransportationPriceCurrency()) && proto.model.CurrencyResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.JoinedOrderTransporterDirectionOrderInfo}
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.JoinedOrderTransporterDirectionOrderInfo;
  return proto.model.JoinedOrderTransporterDirectionOrderInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.JoinedOrderTransporterDirectionOrderInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.JoinedOrderTransporterDirectionOrderInfo}
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationPrice(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationPriceCurrencyId(value);
      break;
    case 4:
      var value = new proto.model.CurrencyResponse;
      reader.readMessage(value,proto.model.CurrencyResponse.deserializeBinaryFromReader);
      msg.setTransportationPriceCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.JoinedOrderTransporterDirectionOrderInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.JoinedOrderTransporterDirectionOrderInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransportationPrice();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransportationPriceCurrencyId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransportationPriceCurrency();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.model.CurrencyResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionOrderInfo} returns this
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transportation_price = 2;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.prototype.getTransportationPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionOrderInfo} returns this
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.prototype.setTransportationPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string transportation_price_currency_id = 3;
 * @return {string}
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.prototype.getTransportationPriceCurrencyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.JoinedOrderTransporterDirectionOrderInfo} returns this
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.prototype.setTransportationPriceCurrencyId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CurrencyResponse transportation_price_currency = 4;
 * @return {?proto.model.CurrencyResponse}
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.prototype.getTransportationPriceCurrency = function() {
  return /** @type{?proto.model.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CurrencyResponse, 4));
};


/**
 * @param {?proto.model.CurrencyResponse|undefined} value
 * @return {!proto.model.JoinedOrderTransporterDirectionOrderInfo} returns this
*/
proto.model.JoinedOrderTransporterDirectionOrderInfo.prototype.setTransportationPriceCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.JoinedOrderTransporterDirectionOrderInfo} returns this
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.prototype.clearTransportationPriceCurrency = function() {
  return this.setTransportationPriceCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.JoinedOrderTransporterDirectionOrderInfo.prototype.hasTransportationPriceCurrency = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.PassportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.PassportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.PassportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PassportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    driverId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    passportNr: jspb.Message.getFieldWithDefault(msg, 3, ""),
    validTill: (f = msg.getValidTill()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    comment: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 14, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.PassportResponse}
 */
proto.model.PassportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.PassportResponse;
  return proto.model.PassportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.PassportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.PassportResponse}
 */
proto.model.PassportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassportNr(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValidTill(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.PassportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.PassportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.PassportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PassportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassportNr();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getValidTill();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.PassportResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PassportResponse} returns this
 */
proto.model.PassportResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string driver_id = 2;
 * @return {string}
 */
proto.model.PassportResponse.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PassportResponse} returns this
 */
proto.model.PassportResponse.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string passport_nr = 3;
 * @return {string}
 */
proto.model.PassportResponse.prototype.getPassportNr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PassportResponse} returns this
 */
proto.model.PassportResponse.prototype.setPassportNr = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp valid_till = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.PassportResponse.prototype.getValidTill = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.PassportResponse} returns this
*/
proto.model.PassportResponse.prototype.setValidTill = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.PassportResponse} returns this
 */
proto.model.PassportResponse.prototype.clearValidTill = function() {
  return this.setValidTill(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.PassportResponse.prototype.hasValidTill = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string comment = 5;
 * @return {string}
 */
proto.model.PassportResponse.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PassportResponse} returns this
 */
proto.model.PassportResponse.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.PassportResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.PassportResponse} returns this
*/
proto.model.PassportResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.PassportResponse} returns this
 */
proto.model.PassportResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.PassportResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.PassportResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.PassportResponse} returns this
*/
proto.model.PassportResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.PassportResponse} returns this
 */
proto.model.PassportResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.PassportResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string created_by_id = 14;
 * @return {string}
 */
proto.model.PassportResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PassportResponse} returns this
 */
proto.model.PassportResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string updated_by_id = 15;
 * @return {string}
 */
proto.model.PassportResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PassportResponse} returns this
 */
proto.model.PassportResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.StorageFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.StorageFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.StorageFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.StorageFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    file: (f = msg.getFile()) && proto.model.StorageFileTemplateResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.StorageFileResponse}
 */
proto.model.StorageFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.StorageFileResponse;
  return proto.model.StorageFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.StorageFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.StorageFileResponse}
 */
proto.model.StorageFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFileType(value);
      break;
    case 2:
      var value = new proto.model.StorageFileTemplateResponse;
      reader.readMessage(value,proto.model.StorageFileTemplateResponse.deserializeBinaryFromReader);
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.StorageFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.StorageFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.StorageFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.StorageFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileType();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.model.StorageFileTemplateResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 file_type = 1;
 * @return {number}
 */
proto.model.StorageFileResponse.prototype.getFileType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.StorageFileResponse} returns this
 */
proto.model.StorageFileResponse.prototype.setFileType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional StorageFileTemplateResponse file = 2;
 * @return {?proto.model.StorageFileTemplateResponse}
 */
proto.model.StorageFileResponse.prototype.getFile = function() {
  return /** @type{?proto.model.StorageFileTemplateResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.StorageFileTemplateResponse, 2));
};


/**
 * @param {?proto.model.StorageFileTemplateResponse|undefined} value
 * @return {!proto.model.StorageFileResponse} returns this
*/
proto.model.StorageFileResponse.prototype.setFile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.StorageFileResponse} returns this
 */
proto.model.StorageFileResponse.prototype.clearFile = function() {
  return this.setFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.StorageFileResponse.prototype.hasFile = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.StorageFileTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.StorageFileTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.StorageFileTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.StorageFileTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    relativeFilePath: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    size: jspb.Message.getFieldWithDefault(msg, 7, 0),
    extension: jspb.Message.getFieldWithDefault(msg, 8, ""),
    contentType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 17, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.StorageFileTemplateResponse}
 */
proto.model.StorageFileTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.StorageFileTemplateResponse;
  return proto.model.StorageFileTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.StorageFileTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.StorageFileTemplateResponse}
 */
proto.model.StorageFileTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelativeFilePath(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSize(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setExtension$(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentType(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.StorageFileTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.StorageFileTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.StorageFileTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.StorageFileTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRelativeFilePath();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getExtension$();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getContentType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.StorageFileTemplateResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.StorageFileTemplateResponse} returns this
 */
proto.model.StorageFileTemplateResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.model.StorageFileTemplateResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.StorageFileTemplateResponse} returns this
 */
proto.model.StorageFileTemplateResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string full_name = 4;
 * @return {string}
 */
proto.model.StorageFileTemplateResponse.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.StorageFileTemplateResponse} returns this
 */
proto.model.StorageFileTemplateResponse.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string relative_file_path = 5;
 * @return {string}
 */
proto.model.StorageFileTemplateResponse.prototype.getRelativeFilePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.StorageFileTemplateResponse} returns this
 */
proto.model.StorageFileTemplateResponse.prototype.setRelativeFilePath = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.model.StorageFileTemplateResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.StorageFileTemplateResponse} returns this
 */
proto.model.StorageFileTemplateResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 size = 7;
 * @return {number}
 */
proto.model.StorageFileTemplateResponse.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.StorageFileTemplateResponse} returns this
 */
proto.model.StorageFileTemplateResponse.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string extension = 8;
 * @return {string}
 */
proto.model.StorageFileTemplateResponse.prototype.getExtension$ = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.StorageFileTemplateResponse} returns this
 */
proto.model.StorageFileTemplateResponse.prototype.setExtension$ = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string content_type = 9;
 * @return {string}
 */
proto.model.StorageFileTemplateResponse.prototype.getContentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.StorageFileTemplateResponse} returns this
 */
proto.model.StorageFileTemplateResponse.prototype.setContentType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.StorageFileTemplateResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.StorageFileTemplateResponse} returns this
*/
proto.model.StorageFileTemplateResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.StorageFileTemplateResponse} returns this
 */
proto.model.StorageFileTemplateResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.StorageFileTemplateResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.StorageFileTemplateResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.StorageFileTemplateResponse} returns this
*/
proto.model.StorageFileTemplateResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.StorageFileTemplateResponse} returns this
 */
proto.model.StorageFileTemplateResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.StorageFileTemplateResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string created_by_id = 17;
 * @return {string}
 */
proto.model.StorageFileTemplateResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.StorageFileTemplateResponse} returns this
 */
proto.model.StorageFileTemplateResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string updated_by_id = 18;
 * @return {string}
 */
proto.model.StorageFileTemplateResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.StorageFileTemplateResponse} returns this
 */
proto.model.StorageFileTemplateResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.TrailerTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.TrailerTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.TrailerTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TrailerTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 5, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.TrailerTypeResponse}
 */
proto.model.TrailerTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.TrailerTypeResponse;
  return proto.model.TrailerTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.TrailerTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.TrailerTypeResponse}
 */
proto.model.TrailerTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.TrailerTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.TrailerTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.TrailerTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TrailerTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.TrailerTypeResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TrailerTypeResponse} returns this
 */
proto.model.TrailerTypeResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.model.TrailerTypeResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TrailerTypeResponse} returns this
 */
proto.model.TrailerTypeResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.TrailerTypeResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.TrailerTypeResponse} returns this
*/
proto.model.TrailerTypeResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TrailerTypeResponse} returns this
 */
proto.model.TrailerTypeResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TrailerTypeResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.TrailerTypeResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.TrailerTypeResponse} returns this
*/
proto.model.TrailerTypeResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TrailerTypeResponse} returns this
 */
proto.model.TrailerTypeResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TrailerTypeResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string created_by_id = 5;
 * @return {string}
 */
proto.model.TrailerTypeResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TrailerTypeResponse} returns this
 */
proto.model.TrailerTypeResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string updated_by_id = 6;
 * @return {string}
 */
proto.model.TrailerTypeResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TrailerTypeResponse} returns this
 */
proto.model.TrailerTypeResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.TransporterResponse.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.TransporterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.TransporterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.TransporterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TransporterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    position: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    proto.model.ContactResponse.toObject, includeInstance),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 17, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.TransporterResponse}
 */
proto.model.TransporterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.TransporterResponse;
  return proto.model.TransporterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.TransporterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.TransporterResponse}
 */
proto.model.TransporterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPosition(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 10:
      var value = new proto.model.ContactResponse;
      reader.readMessage(value,proto.model.ContactResponse.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.TransporterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.TransporterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.TransporterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TransporterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPosition();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.model.ContactResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.TransporterResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterResponse} returns this
 */
proto.model.TransporterResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.model.TransporterResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterResponse} returns this
 */
proto.model.TransporterResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.model.TransporterResponse.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterResponse} returns this
 */
proto.model.TransporterResponse.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string position = 4;
 * @return {string}
 */
proto.model.TransporterResponse.prototype.getPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterResponse} returns this
 */
proto.model.TransporterResponse.prototype.setPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.model.TransporterResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterResponse} returns this
 */
proto.model.TransporterResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated ContactResponse contacts = 10;
 * @return {!Array<!proto.model.ContactResponse>}
 */
proto.model.TransporterResponse.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.model.ContactResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.ContactResponse, 10));
};


/**
 * @param {!Array<!proto.model.ContactResponse>} value
 * @return {!proto.model.TransporterResponse} returns this
*/
proto.model.TransporterResponse.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.model.ContactResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.ContactResponse}
 */
proto.model.TransporterResponse.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.model.ContactResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.TransporterResponse} returns this
 */
proto.model.TransporterResponse.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};


/**
 * optional google.protobuf.Timestamp created_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.TransporterResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.TransporterResponse} returns this
*/
proto.model.TransporterResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TransporterResponse} returns this
 */
proto.model.TransporterResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TransporterResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.TransporterResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.TransporterResponse} returns this
*/
proto.model.TransporterResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TransporterResponse} returns this
 */
proto.model.TransporterResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TransporterResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string created_by_id = 17;
 * @return {string}
 */
proto.model.TransporterResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterResponse} returns this
 */
proto.model.TransporterResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string updated_by_id = 18;
 * @return {string}
 */
proto.model.TransporterResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterResponse} returns this
 */
proto.model.TransporterResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.TransporterDirectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.TransporterDirectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.TransporterDirectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TransporterDirectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transporter: (f = msg.getTransporter()) && proto.model.TransporterResponse.toObject(includeInstance, f),
    sourceCountryId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sourceCountry: (f = msg.getSourceCountry()) && proto.model.CountryResponse.toObject(includeInstance, f),
    destinationCountryId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    destinationCountry: (f = msg.getDestinationCountry()) && proto.model.CountryResponse.toObject(includeInstance, f),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 17, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.TransporterDirectionResponse}
 */
proto.model.TransporterDirectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.TransporterDirectionResponse;
  return proto.model.TransporterDirectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.TransporterDirectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.TransporterDirectionResponse}
 */
proto.model.TransporterDirectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterId(value);
      break;
    case 3:
      var value = new proto.model.TransporterResponse;
      reader.readMessage(value,proto.model.TransporterResponse.deserializeBinaryFromReader);
      msg.setTransporter(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceCountryId(value);
      break;
    case 5:
      var value = new proto.model.CountryResponse;
      reader.readMessage(value,proto.model.CountryResponse.deserializeBinaryFromReader);
      msg.setSourceCountry(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationCountryId(value);
      break;
    case 7:
      var value = new proto.model.CountryResponse;
      reader.readMessage(value,proto.model.CountryResponse.deserializeBinaryFromReader);
      msg.setDestinationCountry(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.TransporterDirectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.TransporterDirectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.TransporterDirectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TransporterDirectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransporter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.model.TransporterResponse.serializeBinaryToWriter
    );
  }
  f = message.getSourceCountryId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSourceCountry();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.model.CountryResponse.serializeBinaryToWriter
    );
  }
  f = message.getDestinationCountryId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDestinationCountry();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.model.CountryResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.TransporterDirectionResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterDirectionResponse} returns this
 */
proto.model.TransporterDirectionResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transporter_id = 2;
 * @return {string}
 */
proto.model.TransporterDirectionResponse.prototype.getTransporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterDirectionResponse} returns this
 */
proto.model.TransporterDirectionResponse.prototype.setTransporterId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TransporterResponse transporter = 3;
 * @return {?proto.model.TransporterResponse}
 */
proto.model.TransporterDirectionResponse.prototype.getTransporter = function() {
  return /** @type{?proto.model.TransporterResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.TransporterResponse, 3));
};


/**
 * @param {?proto.model.TransporterResponse|undefined} value
 * @return {!proto.model.TransporterDirectionResponse} returns this
*/
proto.model.TransporterDirectionResponse.prototype.setTransporter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TransporterDirectionResponse} returns this
 */
proto.model.TransporterDirectionResponse.prototype.clearTransporter = function() {
  return this.setTransporter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TransporterDirectionResponse.prototype.hasTransporter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string source_country_id = 4;
 * @return {string}
 */
proto.model.TransporterDirectionResponse.prototype.getSourceCountryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterDirectionResponse} returns this
 */
proto.model.TransporterDirectionResponse.prototype.setSourceCountryId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional CountryResponse source_country = 5;
 * @return {?proto.model.CountryResponse}
 */
proto.model.TransporterDirectionResponse.prototype.getSourceCountry = function() {
  return /** @type{?proto.model.CountryResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CountryResponse, 5));
};


/**
 * @param {?proto.model.CountryResponse|undefined} value
 * @return {!proto.model.TransporterDirectionResponse} returns this
*/
proto.model.TransporterDirectionResponse.prototype.setSourceCountry = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TransporterDirectionResponse} returns this
 */
proto.model.TransporterDirectionResponse.prototype.clearSourceCountry = function() {
  return this.setSourceCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TransporterDirectionResponse.prototype.hasSourceCountry = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string destination_country_id = 6;
 * @return {string}
 */
proto.model.TransporterDirectionResponse.prototype.getDestinationCountryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterDirectionResponse} returns this
 */
proto.model.TransporterDirectionResponse.prototype.setDestinationCountryId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional CountryResponse destination_country = 7;
 * @return {?proto.model.CountryResponse}
 */
proto.model.TransporterDirectionResponse.prototype.getDestinationCountry = function() {
  return /** @type{?proto.model.CountryResponse} */ (
    jspb.Message.getWrapperField(this, proto.model.CountryResponse, 7));
};


/**
 * @param {?proto.model.CountryResponse|undefined} value
 * @return {!proto.model.TransporterDirectionResponse} returns this
*/
proto.model.TransporterDirectionResponse.prototype.setDestinationCountry = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TransporterDirectionResponse} returns this
 */
proto.model.TransporterDirectionResponse.prototype.clearDestinationCountry = function() {
  return this.setDestinationCountry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TransporterDirectionResponse.prototype.hasDestinationCountry = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_enabled = 8;
 * @return {boolean}
 */
proto.model.TransporterDirectionResponse.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.TransporterDirectionResponse} returns this
 */
proto.model.TransporterDirectionResponse.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.TransporterDirectionResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.TransporterDirectionResponse} returns this
*/
proto.model.TransporterDirectionResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TransporterDirectionResponse} returns this
 */
proto.model.TransporterDirectionResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TransporterDirectionResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.TransporterDirectionResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.TransporterDirectionResponse} returns this
*/
proto.model.TransporterDirectionResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TransporterDirectionResponse} returns this
 */
proto.model.TransporterDirectionResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TransporterDirectionResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string created_by_id = 17;
 * @return {string}
 */
proto.model.TransporterDirectionResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterDirectionResponse} returns this
 */
proto.model.TransporterDirectionResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string updated_by_id = 18;
 * @return {string}
 */
proto.model.TransporterDirectionResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterDirectionResponse} returns this
 */
proto.model.TransporterDirectionResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.TransporterDriverResponse.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.TransporterDriverResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.TransporterDriverResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.TransporterDriverResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TransporterDriverResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transporterId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phonesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    passportsList: jspb.Message.toObjectList(msg.getPassportsList(),
    proto.model.PassportResponse.toObject, includeInstance),
    comment: jspb.Message.getFieldWithDefault(msg, 11, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 17, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.TransporterDriverResponse}
 */
proto.model.TransporterDriverResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.TransporterDriverResponse;
  return proto.model.TransporterDriverResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.TransporterDriverResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.TransporterDriverResponse}
 */
proto.model.TransporterDriverResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addPhones(value);
      break;
    case 8:
      var value = new proto.model.PassportResponse;
      reader.readMessage(value,proto.model.PassportResponse.deserializeBinaryFromReader);
      msg.addPassports(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.TransporterDriverResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.TransporterDriverResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.TransporterDriverResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TransporterDriverResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransporterId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhonesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getPassportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.model.PassportResponse.serializeBinaryToWriter
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string transporter_id = 1;
 * @return {string}
 */
proto.model.TransporterDriverResponse.prototype.getTransporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterDriverResponse} returns this
 */
proto.model.TransporterDriverResponse.prototype.setTransporterId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.model.TransporterDriverResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterDriverResponse} returns this
 */
proto.model.TransporterDriverResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 5;
 * @return {string}
 */
proto.model.TransporterDriverResponse.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterDriverResponse} returns this
 */
proto.model.TransporterDriverResponse.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string last_name = 6;
 * @return {string}
 */
proto.model.TransporterDriverResponse.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterDriverResponse} returns this
 */
proto.model.TransporterDriverResponse.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string phones = 7;
 * @return {!Array<string>}
 */
proto.model.TransporterDriverResponse.prototype.getPhonesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.TransporterDriverResponse} returns this
 */
proto.model.TransporterDriverResponse.prototype.setPhonesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.TransporterDriverResponse} returns this
 */
proto.model.TransporterDriverResponse.prototype.addPhones = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.TransporterDriverResponse} returns this
 */
proto.model.TransporterDriverResponse.prototype.clearPhonesList = function() {
  return this.setPhonesList([]);
};


/**
 * repeated PassportResponse passports = 8;
 * @return {!Array<!proto.model.PassportResponse>}
 */
proto.model.TransporterDriverResponse.prototype.getPassportsList = function() {
  return /** @type{!Array<!proto.model.PassportResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.PassportResponse, 8));
};


/**
 * @param {!Array<!proto.model.PassportResponse>} value
 * @return {!proto.model.TransporterDriverResponse} returns this
*/
proto.model.TransporterDriverResponse.prototype.setPassportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.model.PassportResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.PassportResponse}
 */
proto.model.TransporterDriverResponse.prototype.addPassports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.model.PassportResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.TransporterDriverResponse} returns this
 */
proto.model.TransporterDriverResponse.prototype.clearPassportsList = function() {
  return this.setPassportsList([]);
};


/**
 * optional string comment = 11;
 * @return {string}
 */
proto.model.TransporterDriverResponse.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterDriverResponse} returns this
 */
proto.model.TransporterDriverResponse.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.TransporterDriverResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.TransporterDriverResponse} returns this
*/
proto.model.TransporterDriverResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TransporterDriverResponse} returns this
 */
proto.model.TransporterDriverResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TransporterDriverResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.TransporterDriverResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.TransporterDriverResponse} returns this
*/
proto.model.TransporterDriverResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TransporterDriverResponse} returns this
 */
proto.model.TransporterDriverResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TransporterDriverResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string created_by_id = 17;
 * @return {string}
 */
proto.model.TransporterDriverResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterDriverResponse} returns this
 */
proto.model.TransporterDriverResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string updated_by_id = 18;
 * @return {string}
 */
proto.model.TransporterDriverResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterDriverResponse} returns this
 */
proto.model.TransporterDriverResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.TransporterTrailerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.TransporterTrailerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.TransporterTrailerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TransporterTrailerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transporterId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    plateNr: jspb.Message.getFieldWithDefault(msg, 5, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 17, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.TransporterTrailerResponse}
 */
proto.model.TransporterTrailerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.TransporterTrailerResponse;
  return proto.model.TransporterTrailerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.TransporterTrailerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.TransporterTrailerResponse}
 */
proto.model.TransporterTrailerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlateNr(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.TransporterTrailerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.TransporterTrailerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.TransporterTrailerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TransporterTrailerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransporterId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlateNr();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string transporter_id = 1;
 * @return {string}
 */
proto.model.TransporterTrailerResponse.prototype.getTransporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterTrailerResponse} returns this
 */
proto.model.TransporterTrailerResponse.prototype.setTransporterId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.model.TransporterTrailerResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterTrailerResponse} returns this
 */
proto.model.TransporterTrailerResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string plate_nr = 5;
 * @return {string}
 */
proto.model.TransporterTrailerResponse.prototype.getPlateNr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterTrailerResponse} returns this
 */
proto.model.TransporterTrailerResponse.prototype.setPlateNr = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string comment = 6;
 * @return {string}
 */
proto.model.TransporterTrailerResponse.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterTrailerResponse} returns this
 */
proto.model.TransporterTrailerResponse.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.TransporterTrailerResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.TransporterTrailerResponse} returns this
*/
proto.model.TransporterTrailerResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TransporterTrailerResponse} returns this
 */
proto.model.TransporterTrailerResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TransporterTrailerResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.TransporterTrailerResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.TransporterTrailerResponse} returns this
*/
proto.model.TransporterTrailerResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TransporterTrailerResponse} returns this
 */
proto.model.TransporterTrailerResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TransporterTrailerResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string created_by_id = 17;
 * @return {string}
 */
proto.model.TransporterTrailerResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterTrailerResponse} returns this
 */
proto.model.TransporterTrailerResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string updated_by_id = 18;
 * @return {string}
 */
proto.model.TransporterTrailerResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterTrailerResponse} returns this
 */
proto.model.TransporterTrailerResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.TransporterTruckResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.TransporterTruckResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.TransporterTruckResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TransporterTruckResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transporterId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    plateNr: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdById: jspb.Message.getFieldWithDefault(msg, 17, ""),
    updatedById: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.TransporterTruckResponse}
 */
proto.model.TransporterTruckResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.TransporterTruckResponse;
  return proto.model.TransporterTruckResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.TransporterTruckResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.TransporterTruckResponse}
 */
proto.model.TransporterTruckResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlateNr(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedById(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedById(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.TransporterTruckResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.TransporterTruckResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.TransporterTruckResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TransporterTruckResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransporterId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlateNr();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedById();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getUpdatedById();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string transporter_id = 1;
 * @return {string}
 */
proto.model.TransporterTruckResponse.prototype.getTransporterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterTruckResponse} returns this
 */
proto.model.TransporterTruckResponse.prototype.setTransporterId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.model.TransporterTruckResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterTruckResponse} returns this
 */
proto.model.TransporterTruckResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string plate_nr = 5;
 * @return {string}
 */
proto.model.TransporterTruckResponse.prototype.getPlateNr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterTruckResponse} returns this
 */
proto.model.TransporterTruckResponse.prototype.setPlateNr = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.TransporterTruckResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.TransporterTruckResponse} returns this
*/
proto.model.TransporterTruckResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TransporterTruckResponse} returns this
 */
proto.model.TransporterTruckResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TransporterTruckResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.TransporterTruckResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.TransporterTruckResponse} returns this
*/
proto.model.TransporterTruckResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.TransporterTruckResponse} returns this
 */
proto.model.TransporterTruckResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.TransporterTruckResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string created_by_id = 17;
 * @return {string}
 */
proto.model.TransporterTruckResponse.prototype.getCreatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterTruckResponse} returns this
 */
proto.model.TransporterTruckResponse.prototype.setCreatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string updated_by_id = 18;
 * @return {string}
 */
proto.model.TransporterTruckResponse.prototype.getUpdatedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.TransporterTruckResponse} returns this
 */
proto.model.TransporterTruckResponse.prototype.setUpdatedById = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.UserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.UserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.UserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.UserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    primaryPhone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    primaryEmail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    role: jspb.Message.getFieldWithDefault(msg, 7, 0),
    lang: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.UserResponse}
 */
proto.model.UserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.UserResponse;
  return proto.model.UserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.UserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.UserResponse}
 */
proto.model.UserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryEmail(value);
      break;
    case 7:
      var value = /** @type {!proto.model.UserRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLang(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.UserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.UserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.UserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.UserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPrimaryPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrimaryEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getLang();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.UserResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.UserResponse} returns this
 */
proto.model.UserResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.model.UserResponse.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.UserResponse} returns this
 */
proto.model.UserResponse.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.model.UserResponse.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.UserResponse} returns this
 */
proto.model.UserResponse.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.model.UserResponse.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.UserResponse} returns this
 */
proto.model.UserResponse.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string primary_phone = 5;
 * @return {string}
 */
proto.model.UserResponse.prototype.getPrimaryPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.UserResponse} returns this
 */
proto.model.UserResponse.prototype.setPrimaryPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string primary_email = 6;
 * @return {string}
 */
proto.model.UserResponse.prototype.getPrimaryEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.UserResponse} returns this
 */
proto.model.UserResponse.prototype.setPrimaryEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional UserRole role = 7;
 * @return {!proto.model.UserRole}
 */
proto.model.UserResponse.prototype.getRole = function() {
  return /** @type {!proto.model.UserRole} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.model.UserRole} value
 * @return {!proto.model.UserResponse} returns this
 */
proto.model.UserResponse.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string lang = 8;
 * @return {string}
 */
proto.model.UserResponse.prototype.getLang = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.UserResponse} returns this
 */
proto.model.UserResponse.prototype.setLang = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.UserResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.UserResponse} returns this
*/
proto.model.UserResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.UserResponse} returns this
 */
proto.model.UserResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.UserResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.UserResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.UserResponse} returns this
*/
proto.model.UserResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.UserResponse} returns this
 */
proto.model.UserResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.UserResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool is_active = 11;
 * @return {boolean}
 */
proto.model.UserResponse.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.UserResponse} returns this
 */
proto.model.UserResponse.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.UserMetaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.UserMetaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.UserMetaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.UserMetaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    role: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.UserMetaResponse}
 */
proto.model.UserMetaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.UserMetaResponse;
  return proto.model.UserMetaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.UserMetaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.UserMetaResponse}
 */
proto.model.UserMetaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {!proto.model.UserRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.UserMetaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.UserMetaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.UserMetaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.UserMetaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.UserMetaResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.UserMetaResponse} returns this
 */
proto.model.UserMetaResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.model.UserMetaResponse.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.UserMetaResponse} returns this
 */
proto.model.UserMetaResponse.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.model.UserMetaResponse.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.UserMetaResponse} returns this
 */
proto.model.UserMetaResponse.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.model.UserMetaResponse.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.UserMetaResponse} returns this
 */
proto.model.UserMetaResponse.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional UserRole role = 5;
 * @return {!proto.model.UserRole}
 */
proto.model.UserMetaResponse.prototype.getRole = function() {
  return /** @type {!proto.model.UserRole} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.model.UserRole} value
 * @return {!proto.model.UserMetaResponse} returns this
 */
proto.model.UserMetaResponse.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.UserMetaResponse.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.UserMetaResponse} returns this
*/
proto.model.UserMetaResponse.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.UserMetaResponse} returns this
 */
proto.model.UserMetaResponse.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.UserMetaResponse.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.UserMetaResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.UserMetaResponse} returns this
*/
proto.model.UserMetaResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.UserMetaResponse} returns this
 */
proto.model.UserMetaResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.UserMetaResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * @enum {number}
 */
proto.model.AddressProvider = {
  ADDRESS_PROVIDER_UNKNOWN: 0,
  ADDRESS_PROVIDER_MANUAL: 1,
  ADDRESS_PROVIDER_GOOGLE: 2,
  ADDRESS_PROVIDER_HERE: 3
};

/**
 * @enum {number}
 */
proto.model.DealStatus = {
  DEAL_STATUS_UNKNOWN: 0,
  DEAL_STATUS_CANCELED: 1,
  DEAL_STATUS_NEW: 10,
  DEAL_STATUS_EXECUTOR: 20,
  DEAL_STATUS_CONTRACT: 30,
  DEAL_STATUS_DISPATCH_NEW: 40,
  DEAL_STATUS_PENDING_LOADS: 50,
  DEAL_STATUS_LOADING_PROCESS: 60,
  DEAL_STATUS_ON_ROUTE: 70,
  DEAL_STATUS_DELIVERY_PROCESS: 80,
  DEAL_STATUS_FINISHED: 100
};

/**
 * @enum {number}
 */
proto.model.DealLoadStatus = {
  DEAL_LOAD_STATUS_UNKNOWN: 0,
  DEAL_LOAD_STATUS_UNPROCESSED: 1,
  DEAL_LOAD_STATUS_LOT_OF_SPACE: 2,
  DEAL_LOAD_STATUS_THERE_IS_STILL_ROOM: 3,
  DEAL_LOAD_STATUS_FULL: 4
};

/**
 * @enum {number}
 */
proto.model.Model = {
  MODEL_UNKNOWN: 0,
  MODEL_ORDER: 1,
  MODEL_DEAL: 2,
  MODEL_ORDER_ITEM: 3,
  MODEL_ORDER_COMMENT: 4,
  MODEL_COMPANY: 51,
  MODEL_CONTACT: 52,
  MODEL_DOC_TYPE: 53,
  MODEL_ORDER_REPORTED_PROBLEM: 54,
  MODEL_ORDER_REPORTED_PROBLEM_TYPE: 55
};

/**
 * @enum {number}
 */
proto.model.DealType = {
  DEAL_TYPE_UNKNOWN: 0,
  DEAL_TYPE_FULL: 1,
  DEAL_TYPE_GROUP: 2
};

/**
 * @enum {number}
 */
proto.model.ItemType = {
  ITEM_TYPE_UNKNOWN: 0,
  ITEM_TYPE_PALLET: 1,
  ITEM_TYPE_CUSTOM_ITEM: 2
};

/**
 * @enum {number}
 */
proto.model.PalletStandard = {
  PALLET_STANDARD_UNKNOWN: 0,
  PALLET_STANDARD_EUROPEAN: 1,
  PALLET_STANDARD_INDUSTRIAL: 2
};

/**
 * @enum {number}
 */
proto.model.WeightUnit = {
  WEIGHT_UNIT_UNKNOWN: 0,
  WEIGHT_UNIT_T: 1,
  WEIGHT_UNIT_KG: 2
};

/**
 * @enum {number}
 */
proto.model.OrderBrokerageServices = {
  BROKERAGE_SERVICES_UNKNOWN: 0,
  BROKERAGE_SERVICES_THEIR: 1,
  BROKERAGE_SERVICES_OUR: 2
};

/**
 * @enum {number}
 */
proto.model.DispatchLoadingStatus = {
  DISPATCH_LOADING_STATUS_UNKNOWN: 0,
  DISPATCH_LOADING_STATUS_CHECK_IN_LOADING_SITE: 1,
  DISPATCH_LOADING_STATUS_PENDING_LOADING_PROCESS: 2,
  DISPATCH_LOADING_STATUS_CUSTOMS_DOC_AND_VALIDATION: 3,
  DISPATCH_LOADING_STATUS_BROKER_APPROVED: 4
};

/**
 * @enum {number}
 */
proto.model.DispatchDeliveryStatus = {
  DISPATCH_DELIVERY_STATUS_UNKNOWN: 0,
  DISPATCH_DELIVERY_STATUS_BORDER_ARRIVAL: 10,
  DISPATCH_DELIVERY_STATUS_TRANSIT_CUSTOMS_STAGE: 20,
  DISPATCH_DELIVERY_STATUS_UNLOADING_PROCESS: 30
};

/**
 * @enum {number}
 */
proto.model.OrderReportedProblemCulprit = {
  ORDER_REPORTED_PROBLEM_CULPRIT_UNKNOWN: 0,
  ORDER_REPORTED_PROBLEM_CULPRIT_CLIENT: 1,
  ORDER_REPORTED_PROBLEM_CULPRIT_TRANSPORTER: 2
};

/**
 * @enum {number}
 */
proto.model.UserRole = {
  NO_ROLE: 0,
  ADMIN: 1,
  MANAGER: 2,
  DISPATCHER: 3,
  BROKER: 4
};

goog.object.extend(exports, proto.model);
